import React from "react"
import { Paper, Stack, Typography } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import PersonIcon from "@material-ui/icons/PersonOutlineOutlined"

import { typography } from "../../services/theme/typography"

const useStyles = makeStyles((theme) =>
  createStyles({
    paperRoot: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      borderRadius: 0,
      boxShadow: "none",
      padding: theme.spacing(6, 10, 6, 8),
    },
    chatIcon: {
      fontSize: theme.spacing(11),
      color: "#677278",
    },
    infoText: {
      fontSize: theme.spacing(2.5),
      color: "#1771A6",
      textAlign: "center",
      ...typography.circularXXMedium,
    },
  }),
)

const EmptyList = ({ searchCompleted }: { searchCompleted: boolean }) => {
  const classes = useStyles()

  return (
    <Paper className={classes.paperRoot}>
      <Stack spacing={5} flex={1} alignItems="center">
        <PersonIcon className={classes.chatIcon} />

        <Typography className={classes.infoText}>
          {searchCompleted
            ? "No results found!"
            : "Type a name to start searching for episodes!"}
        </Typography>
      </Stack>
    </Paper>
  )
}

export { EmptyList }
