import { Instance, SnapshotOut, types, isAlive } from "mobx-state-tree"
import { uniqBy, prop } from "ramda"

import { withEnvironment, withRootStore } from "../extensions"
import { replaceObjectById } from "../../utils/replace-object-in-array"
import { removeObjectById } from "../../utils/remove-object-from-array"
import { FeedPost, FeedPostModel } from "./feed-post"
import { formatAffinityPostsList } from "./utils"
import { AffinityNote, AffinityNoteModel } from "../affinity-note"

export const LIMIT = 20
export const PAGE = 1

export const ALL_POSTS = "all_posts"
export const ENGAGEMENT_PROMPT_REPLIES = "engagement_prompt_replies"
export const MUTED_POSTS = "muted_posts"
export type FILTER_BY_TYPE =
  | typeof ALL_POSTS
  | typeof ENGAGEMENT_PROMPT_REPLIES
  | typeof MUTED_POSTS

/**
 * Represents a pagination model.
 */
export const AffinityGroupFeedPaginationModel = types
  .model("AffinityGroupFeedPagination")
  .extend(withEnvironment)
  .extend(withRootStore)
  .props({
    page: types.optional(types.number, PAGE),
    next: types.optional(types.maybeNull(types.number), null),
    limit: types.optional(types.number, LIMIT),
    isEndReached: types.optional(types.boolean, false),
    filter: types.optional(
      types.union(
        types.literal(ALL_POSTS),
        types.literal(ENGAGEMENT_PROMPT_REPLIES),
        types.literal(MUTED_POSTS),
      ),
      ALL_POSTS,
    ),
    posts: types.optional(types.array(FeedPostModel), []),
    groupNotes: types.optional(types.array(AffinityNoteModel), []),
  })
  .actions((self) => ({
    setNextPage(value: { next: number | null; isEndReached: boolean }) {
      self.next = value.next
      self.isEndReached = value.isEndReached
    },
    setPage(value: number) {
      self.page = value
    },
    setLimit(value: number) {
      self.limit = value
    },
    setFilter(value: FILTER_BY_TYPE) {
      self.filter = value
    },

    setPostsList(value: FeedPost[]) {
      self.posts.replace(value)
    },
    appendPosts(value: FeedPost[]) {
      self.posts.replace(uniqBy(prop("id"), [...self.posts, ...value]))
    },
    unshiftLatestPosts(value: FeedPost[]) {
      self.posts.replace(uniqBy(prop("id"), [...value, ...self.posts]))
    },

    updatePost(value: FeedPost) {
      const updatedPostsList = replaceObjectById(self.posts, value)

      self.posts.replace(updatedPostsList as FeedPost[])
    },

    deletePost(value: string) {
      const updatedPostsList = removeObjectById(self.posts, value)
      self.posts.replace(updatedPostsList as FeedPost[])
    },

    setGroupNotes(value: AffinityNote[]) {
      self.groupNotes.replace(
        uniqBy(prop("id"), [...value, ...self.groupNotes]),
      )
    },

    resetPosts() {
      if (isAlive(self.posts)) {
        self.posts.clear()
      }
    },
  }))
  .actions((self) => ({
    togglePostIsMuted(postId: string) {
      const post = self.posts.find((post) => post.id === postId)
      post.toggleIsMuted()
    },
    togglePostIsBanned(memberId: string) {
      self.posts.forEach((post) => {
        if (post.personID === memberId) {
          post.toggleIsBanned()
        }
      })
    },
    resetPagination() {
      self.page = PAGE
      self.limit = LIMIT
      self.filter = ALL_POSTS
      self.next = null
      self.isEndReached = false
    },
  }))
  .actions((self) => ({
    async apiRefreshAffinityGroupPosts(groupID: string) {
      const { api } = self.environment
      const { limit, filter } = self

      const { kind, data } = await api.getAffinityGroupPosts({
        groupID,
        limit,
        filter,
        timestamp: undefined,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      const activePosts = formatAffinityPostsList(data.data)

      self.unshiftLatestPosts(activePosts)
    },
    async apiLoadAffinityGroupPosts(groupID: string) {
      const { api } = self.environment
      const {
        next,
        limit,
        filter,
        setPostsList,
        appendPosts,
        setNextPage,
      } = self

      if (self.isEndReached) {
        return
      }

      const { kind, data } = await api.getAffinityGroupPosts({
        groupID,
        limit,
        filter,
        timestamp: next || undefined,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      const activePosts = formatAffinityPostsList(data.data)
      const isEndReached = data.data.length < limit
      const isPaginating = !!next

      //if these posts were result of pagination, append them to the main posts list
      //otherwise replace the current list with the posts list from the response
      if (isPaginating) {
        appendPosts(activePosts)
      } else {
        setPostsList(activePosts)
      }
      setNextPage({
        next: isEndReached ? null : data.paginationMetadata.next,
        isEndReached,
      })
    },
    async apiGetAffinityGroupNotes(groupID: string) {
      const { api } = self.environment

      const { kind, data } = await api.getAffinityGroupNotes(groupID)

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.setGroupNotes(data.data)
    },
  }))
  .actions((self) => ({
    async apiGetAffinityGroupPosts(groupID: string) {
      await self.apiLoadAffinityGroupPosts(groupID)

      //this handles the case when the initial list contains only deleted posts
      //and the feed would not be scrollable to get the next feed items
      if (self.posts.length < 5 && !self.isEndReached) {
        self.apiLoadAffinityGroupPosts(groupID)
      }
    },
  }))
  .actions((self) => ({
    async apiPilotCoachMuteAffinityPost({
      groupID,
      postId,
    }: {
      groupID: string
      postId: string
    }) {
      const { api } = self.environment

      const { kind, data } = await api.pilotCoachMuteAffinityPost({
        groupID,
        postId,
        muteReason: "",
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      self.togglePostIsMuted(postId)
    },
    async apiPilotCoachUnMuteAffinityPost({
      groupID,
      postId,
    }: {
      groupID: string
      postId: string
    }) {
      const { api } = self.environment

      const { kind, data } = await api.pilotCoachUnMuteAffinityPost({
        groupID,
        postId,
        muteReason: "",
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      self.togglePostIsMuted(postId)
    },
    async apiPilotCoachBanAffinityMember({
      groupID,
      memberId,
    }: {
      groupID: string
      memberId: string
    }) {
      const { api } = self.environment

      const { kind, data } = await api.pilotCoachBanAffinityMember({
        groupID,
        memberId,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      self.togglePostIsBanned(memberId)
    },
    async apiPilotCoachUnBanAffinityMember({
      groupID,
      memberId,
    }: {
      groupID: string
      memberId: string
    }) {
      const { api } = self.environment

      const { kind, data } = await api.pilotCoachUnBanAffinityMember({
        groupID,
        memberId,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      self.togglePostIsBanned(memberId)
    },
    async apiCreateAffinityFeedPost({
      groupID,
      message,
      engagementPrompt,
      inReplyTo,
    }: {
      groupID: string
      message: string
      engagementPrompt: boolean
      inReplyTo: string | null
    }) {
      const { api } = self.environment

      const { kind, data } = await api.createFeedPost({
        type: engagementPrompt ? "engagement_prompt" : "message",
        groupID: groupID,
        message: message,
        engagementPrompt: engagementPrompt,
        sessionID: null,
        inReplyTo: inReplyTo,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
    },
    async apiDeleteAffinityFeedPost(postID: string) {
      const { api } = self.environment

      const { kind, data } = await api.deleteFeedPost({ postID: postID })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
    },
    async apiCreateAffinityGroupNote({
      groupID,
      note,
    }: {
      groupID: string
      note: string
    }) {
      const { api } = self.environment

      const { kind, data } = await api.createAffinityGroupNotes({
        groupID: groupID,
        note: note,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiGetAffinityGroupNotes(groupID)
    },
  }))
  .views((self) => ({
    get isSelectiveFilterActive(): boolean {
      return self.filter !== ALL_POSTS
    },
    get activeQuestionOfTheDay() {
      const postsList = [...self.posts]
      const firstItem = postsList[0]
      if (firstItem?.type === "engagement_prompt") {
        return firstItem
      }
      return null
    },
  }))
  .views((self) => ({
    get feedPostsList() {
      if (self.activeQuestionOfTheDay) {
        return self.posts.slice(1)
      }
      return self.posts
    },
  }))

export interface AffinityGroupFeedPagination
  extends Instance<typeof AffinityGroupFeedPaginationModel> {}
export interface AffinityGroupFeedPaginationSnapshot
  extends SnapshotOut<typeof AffinityGroupFeedPaginationModel> {}
