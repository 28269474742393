import { memo } from "react"
import { Theme, Typography, Stack, Button } from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import moment from "moment"

import { ChatMessage, ChatType } from "../../models/chat-message"
import { typography } from "../../services/theme/typography"
import { Linky } from "../linky"
import { TrainContent } from "../chat-bubble/chat-bubble-extra-content"
import { ReactComponent as DeleteIcon } from "../../assets/images/delete-chat.svg"

interface StyleProps {
  isMessageFromCoach: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    wrapper: {
      margin: theme.spacing(0, 0, 4, 0),
      display: "flex",
      flexDirection: (props) => {
        if (props.isMessageFromCoach) {
          return "row-reverse"
        }
        return "row"
      },
      alignItems: "flex-end",
    },
    messageContainer: {
      width: "100%",
      alignItems: (props) => {
        if (props.isMessageFromCoach) {
          return "flex-end"
        }
        return "flex-start"
      },
    },
    closedChatText: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: theme.spacing(4),
      fontSize: theme.spacing(2),
      color: theme.palette.text.secondary,
      ...typography.circularXXMedium,
    },
    bubbleContainer: {
      display: "flex",
      alignItems: "center",
    },
    bubbleContent: {
      alignItems: (props) => {
        if (props.isMessageFromCoach) {
          return "flex-end"
        }
        return "flex-start"
      },
    },
    coachAvatar: {
      alignSelf: "end",
      width: theme.spacing(5),
      height: theme.spacing(5),
      borderRadius: "50%",
      marginLeft: theme.spacing(2),
    },
    personName: {
      fontSize: theme.spacing(2),
      lineHeight: theme.spacing(2.5),
      color: "#485359",
      ...typography.circularXXMedium,
    },
    bubble: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(2),
      borderRadius: theme.spacing(2),
      border: (props) => {
        if (props.isMessageFromCoach) {
          return "none"
        }
        return `1px solid #B0BABF`
      },
      backgroundColor: (props) => {
        if (props.isMessageFromCoach) {
          return "#1B57A5"
        }
        return theme.palette.common.white
      },
      color: (props) => {
        if (props.isMessageFromCoach) {
          return theme.palette.common.white
        }
        return "#485359"
      },
      maxWidth: "600px",
    },
    message: {
      whiteSpace: "pre-wrap",
      fontSize: theme.spacing(2),
      lineHeight: theme.spacing(3),
      ...typography.circularXXMedium,
    },
    bubbleActionRow: {
      marginTop: theme.spacing(2),
      justifyContent: "space-between",
      alignItems: "center",
    },
    createdAt: {
      display: "block",
      fontSize: theme.spacing(1.75),
      lineHeight: theme.spacing(2.25),
      ...typography.circularXXRegular,
    },
    linky: {
      color: `-webkit-link !important`,
      textDecoration: "underline",
    },
    deleteButton: {
      minWidth: 0,
      padding: 0,
      alignSelf: "flex-end",
      gap: theme.spacing(1),
      color: theme.palette.common.white,
      fontSize: theme.spacing(1.75),
      lineHeight: theme.spacing(2.25),
      ...typography.circularXXMedium,
    },
  }),
)

interface ChatBubbleProps {
  message: ChatMessage
  isMessageFromCurrentPerson: boolean
  isMessageFromCoach: boolean
  showAuthorName?: boolean
  onMessageDelete?: () => void
}

export const ChatBubble = memo(
  ({
    message,
    isMessageFromCurrentPerson,
    isMessageFromCoach,
    showAuthorName,
    onMessageDelete,
  }: ChatBubbleProps) => {
    const classes = useStyles({ isMessageFromCoach })

    if (message.type === ChatType.Direct_Chat_Ended) {
      return (
        <div className={classes.wrapper}>
          <Stack className={classes.messageContainer}>
            <div className={classes.closedChatText}>
              Chat closed on {moment(message.createdAt).format("MMMM Do YYYY")}
            </div>
          </Stack>
        </div>
      )
    }

    return (
      <div className={classes.wrapper}>
        <Stack className={classes.messageContainer}>
          <div className={classes.bubbleContainer}>
            <Stack spacing={1} className={classes.bubbleContent}>
              {showAuthorName && (
                <Typography className={classes.personName}>
                  {isMessageFromCoach && "Coach "}
                  {message?.author?.fullName}
                </Typography>
              )}

              <div className={classes.bubble}>
                {message.type === ChatType.Train && (
                  <TrainContent message={message} />
                )}

                <Typography variant="body1" className={classes.message}>
                  <Linky className={classes.linky} target="_blank">
                    {message.message}
                  </Linky>
                </Typography>

                <Stack
                  className={classes.bubbleActionRow}
                  direction="row"
                  spacing={2}>
                  <Typography className={classes.createdAt} variant="caption">
                    Sent at {moment(message.createdAt).format("h:mm A")}
                  </Typography>

                  {isMessageFromCurrentPerson && onMessageDelete && (
                    <Button
                      className={classes.deleteButton}
                      onClick={onMessageDelete}>
                      <DeleteIcon />
                      Delete
                    </Button>
                  )}
                </Stack>
              </div>
            </Stack>

            {isMessageFromCoach && (
              <img
                src={message.author.avatarURL}
                className={classes.coachAvatar}
                alt="Person Avatar"
              />
            )}
          </div>
        </Stack>
      </div>
    )
  },
  (prevProps, nextProps) => {
    const areEqual =
      prevProps.isMessageFromCurrentPerson ===
        nextProps.isMessageFromCurrentPerson &&
      prevProps.isMessageFromCoach === nextProps.isMessageFromCoach &&
      prevProps.message?.log?.verified === nextProps.message?.log?.verified &&
      prevProps.message.message === nextProps.message.message &&
      prevProps.message.meta === nextProps.message.meta &&
      prevProps.message.isSavedChat === nextProps.message.isSavedChat &&
      prevProps.message.dateLabel === nextProps.message.dateLabel

    return areEqual
  },
)
