import { types, Instance, SnapshotOut } from "mobx-state-tree"
import * as customTypes from "../types"
import { validate, ValidationRules } from "../../utils/validate"

export const AffinityNoteModel = types
  .model("AffinityNote")
  .props({
    id: types.identifier,
    coachID: types.maybeNull(types.string),
    groupID: types.maybeNull(types.string),
    coachAvatarURL: types.maybeNull(types.string),
    coachName: types.maybeNull(types.string),
    deleted: types.boolean,
    note: types.maybeNull(types.string),
    createdAt: types.optional(customTypes.iso8601, null),
    updatedAt: types.optional(customTypes.iso8601, null),
  })
  .actions((self) => ({
    setNote(value: string) {
      self.note = value
    },

    reset() {
      self.note = ""
    },
  }))
  .views((self) => ({
    get createNewNoteErrors() {
      return validate(CREATE_NEW_NOTE_RULES, self)
    },
    get isEditMode() {
      return self.id !== "-1"
    },
  }))

const CREATE_NEW_NOTE_RULES: ValidationRules = {
  noteText: {
    presence: {
      allowEmpty: false,
      message: "*Please enter a note with at least 1 character before saving",
    },
  },
}

export interface AffinityNote extends Instance<typeof AffinityNoteModel> {}
export interface AffinityNoteSnapshot
  extends SnapshotOut<typeof AffinityNoteModel> {}
