import { types, Instance, SnapshotOut } from "mobx-state-tree"
import moment from "moment"
import * as customTypes from "../types"
import {
  ChatMessageLogModel,
  ChatType,
  chatTypeLiterals,
} from "./chat-message-log"
import { ChatMessageIdeaModel } from "./chat-message-idea"
import { ChatMessageTrainModel } from "./chat-message-train"
import { Person, PersonModel } from "../person"

export const ChatMessageModel = types
  .model("ChatMessage")
  .props({
    id: types.string,
    createdAt: types.optional(customTypes.iso8601, moment().toDate()),
    roomID: types.maybeNull(types.string),
    cohortID: types.optional(types.maybeNull(types.string), null),
    authorID: types.optional(types.maybeNull(types.string), null),
    author: types.optional(PersonModel, () => PersonModel.create({ id: "-1" })),
    message: types.maybeNull(types.string),
    type: types.optional(types.union(...chatTypeLiterals), ChatType.Message),
    meta: types.optional(types.maybeNull(types.string), ""),
    log: types.maybeNull(ChatMessageLogModel),
    idea: types.maybeNull(ChatMessageIdeaModel),
    trainVideoDetails: types.optional(
      types.maybeNull(ChatMessageTrainModel),
      null,
    ),
    dateLabel: types.optional(types.maybeNull(types.string), ""),
    isSavedChat: types.optional(types.maybeNull(types.boolean), false),
    isAnonymous: types.optional(types.maybeNull(types.boolean), false),
    personID: types.optional(types.maybeNull(types.string), ""),
    coachID: types.optional(types.maybeNull(types.string), ""),
    groupID: types.optional(types.maybeNull(types.string), ""),
    sessionID: types.optional(types.maybeNull(types.string), ""),
  })
  .actions((self) => ({
    setCreatedAt(value: Date) {
      self.createdAt = value
    },
    setRoomID(value: string) {
      self.roomID = value
    },
    setCohortID(value: string | null) {
      self.cohortID = value
    },
    setAuthorID(value: string | null) {
      self.authorID = value
    },
    setAuthor(value: Person) {
      self.author = value
    },
    setMessage(value: string | null) {
      self.message = value
    },
    setDateLabel(value: string | null) {
      self.dateLabel = value
    },
    setIsSavedChat(value: boolean | null) {
      self.isSavedChat = value
    },
  }))
  .views((self) => ({
    get createdAtFromNow() {
      return moment(self.createdAt).fromNow()
    },
  }))

export interface ChatMessage extends Instance<typeof ChatMessageModel> {}
export interface ChatMessageSnapshot
  extends SnapshotOut<typeof ChatMessageModel> {}
