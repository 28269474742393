import React from "react"
import { observer } from "mobx-react-lite"
import { DialogActions, Button } from "@material-ui/core"

import { useStores } from "../../models/root-store"
import { useChatContent } from "./contentContext"

const Footer = observer(() => {
  const { pilotTrainerStore } = useStores()

  const { trainEpisodesPagination } = pilotTrainerStore

  const {
    selectedVideoID,
    setSelectedVideoID,
    submitContent,
    onClose,
  } = useChatContent()

  const onSubmit = () => {
    const selectedVideo = trainEpisodesPagination.episodes.find(
      (v) => v.id === selectedVideoID,
    )
    submitContent(selectedVideo)
    onClose()
    setSelectedVideoID(null)
  }

  return (
    <DialogActions>
      <Button
        onClick={onSubmit}
        color="primary"
        variant="contained"
        disabled={!Boolean(selectedVideoID)}>
        Insert
      </Button>

      <Button onClick={onClose} color="warning" variant="outlined">
        Close
      </Button>
    </DialogActions>
  )
})

export { Footer }
