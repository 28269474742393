import React, { createContext, useState, useContext, ReactNode } from "react"

import { MslEvent } from "../../models/msl-event"

export enum ContentTabs {
  TRAIN,
  TAB2,
  TAB3,
}
export interface ChatContentContextProps {
  activeTab: ContentTabs
  setActiveTab: (tab: ContentTabs) => void
  selectedVideoID: string | null
  setSelectedVideoID: (id: string | null) => void
  submitContent?: (content: MslEvent) => void
  onClose?: () => void
  searchTerm: string
  setSearchTerm: (value: string) => void
  isSearchActive: boolean
  searchCompleted: boolean
  setSearchCompleted: (value: boolean) => void
}

const ChatContentContext = createContext<ChatContentContextProps | undefined>(
  undefined,
)
const ChatContentProvider = ({
  children,
  initialValues,
}: {
  children: ReactNode
  initialValues?: Partial<ChatContentContextProps>
}) => {
  const [activeTab, setActiveTab] = useState<ContentTabs>(ContentTabs.TRAIN)
  const [selectedVideoID, setSelectedVideoID] = useState<string | null>(null)
  const [searchTerm, setSearchTerm] = useState<string>("")
  const [searchCompleted, setSearchCompleted] = useState<boolean>(false)

  /**
   * Local flag which checks if the search term is empty or not
   */
  const isSearchActive = Boolean(searchTerm.trim().length)

  return (
    <ChatContentContext.Provider
      value={{
        activeTab,
        setActiveTab,
        selectedVideoID,
        setSelectedVideoID,
        searchTerm,
        setSearchTerm,
        isSearchActive,
        searchCompleted,
        setSearchCompleted,
        ...initialValues,
      }}>
      {children}
    </ChatContentContext.Provider>
  )
}

const useChatContent = () => {
  return useContext(ChatContentContext)
}

export { ChatContentProvider, useChatContent }
