import { types, Instance, SnapshotOut, clone } from "mobx-state-tree"
import * as customTypes from "../types"

import { TriCategory, TriCategoryModel } from "../tri-category"
import { UploadFile, UploadFileModel } from "../upload-file"
import { validate, ValidationRules } from "../../utils/validate"

export const ModuleModel = types
  .model("Module")
  .props({
    id: types.identifier,
    title: types.maybeNull(types.string),
    iconURI: types.maybeNull(types.string),
    iconURILarge: types.maybeNull(types.string),
    iconBaseURI: types.maybeNull(types.string),
    iconFullURI: types.maybeNull(types.string),
    createdAt: types.maybeNull(customTypes.iso8601),
    updatedAt: types.maybeNull(customTypes.iso8601),
    categories: types.optional(types.array(TriCategoryModel), []),
    uploadFiles: types.optional(
      types.model({
        iconURI: types.optional(UploadFileModel, () =>
          UploadFileModel.create({
            id: "iconURI",
          }),
        ),
        iconURILarge: types.optional(UploadFileModel, () =>
          UploadFileModel.create({
            id: "iconURILarge",
          }),
        ),
      }),
      {},
    ),
  })
  .actions((self) => ({
    setTitle(value: string) {
      self.title = value
    },
    setIconURI(value: string | null) {
      self.iconURI = value
    },
    setIconURILarge(value: string | null) {
      self.iconURILarge = value
    },
    setCategories(value: TriCategory[]) {
      self.categories.replace(value.map((e) => clone(e)))
    },
    setContentUrl(id: "iconURI" | "iconURILarge", value: string) {
      self[id] = value
    },
    setUploadFile(value: UploadFile) {
      self.uploadFiles[value.id] = value
    },

    reset() {
      self.title = ""
      self.iconURI = ""
      self.iconURILarge = ""
      self.iconFullURI = ""
      self.categories.replace([])
    },
  }))
  .views((self) => ({
    get createModuleErrors() {
      return validate(CREATE_NEW_MODULE_RULES, self)
    },
    get isEditMode() {
      return self.id !== "-1"
    },
  }))

const CREATE_NEW_MODULE_RULES: ValidationRules = {
  title: {
    presence: { allowEmpty: false, message: "required" },
  },
  iconURI: {
    fileUpload: {},
  },
}

export interface Module extends Instance<typeof ModuleModel> {}
export interface ModuleSnapshot extends SnapshotOut<typeof ModuleModel> {}
