import { types, Instance, SnapshotOut } from "mobx-state-tree"
import * as customTypes from "../types"

export const AffinitySessionModel = types
  .model("Session")
  .props({
    id: types.identifier,
    participantID: types.maybeNull(types.string),
    participantName: types.optional(types.maybeNull(types.string), ""),
    messageText: types.optional(types.maybeNull(types.string), ""),
    authorFirstName: types.optional(types.maybeNull(types.string), ""),
    authorEmail: types.optional(types.maybeNull(types.string), ""),
    avatarURL: types.optional(types.maybeNull(types.string), ""),
    createdAt: types.optional(types.maybeNull(customTypes.iso8601), ""),
  })
  .views((self) => ({
    get isValid() {
      return self.id !== "-1"
    },
  }))

export interface AffinitySession
  extends Instance<typeof AffinitySessionModel> { }
export interface AffinitySessionSnapshot
  extends SnapshotOut<typeof AffinitySessionModel> { }
