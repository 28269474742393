import { GridColDef, getGridStringOperators } from "@mui/x-data-grid"

import { MslEvent } from "../../models/msl-event"
import { Trainer } from "../../models/msl-event/trainer"

export const dateColumnSortMethod = (v1: Date, v2: Date) =>
  new Date(v2).valueOf() - new Date(v1).valueOf()

export const trainerColumnSortMethod = (
  v1: string | null,
  v2: string | null,
  trainersMap: { [id: string]: Trainer },
) => trainersMap[v1]?.fullName?.localeCompare?.(trainersMap[v2]?.fullName)

export const trainerColumnFilterMethod = (trainersMap: {
  [id: string]: Trainer
}) =>
  getGridStringOperators().map((operator) => {
    const newOperator = { ...operator }
    const newGetApplyFilterFn = (filterItem, column: GridColDef) => {
      return (params): boolean => {
        return trainersMap[params?.value]?.fullName
          ?.toLocaleLowerCase?.()
          .includes?.(filterItem?.value?.toLocaleLowerCase?.() || "")
      }
    }
    newOperator.getApplyFilterFn = newGetApplyFilterFn
    return newOperator
  })

export const topicColumnSortMethod = (v1: MslEvent, v2: MslEvent) =>
  v1?.name.localeCompare(v2?.name)

export const topicColumnFilterMethod = getGridStringOperators().map(
  (operator) => {
    const newOperator = { ...operator }
    const newGetApplyFilterFn = (filterItem, column: GridColDef) => {
      return (params): boolean => {
        return params?.value?.name
          ?.toLocaleLowerCase?.()
          .includes?.(filterItem?.value?.toLocaleLowerCase?.() || "")
      }
    }
    newOperator.getApplyFilterFn = newGetApplyFilterFn
    return newOperator
  },
)
