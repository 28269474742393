import React from "react"

import { Box, Stack, Typography, Theme, Modal, Button } from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"

import { typography } from "../../services/theme/typography"

interface CloseChatModalProps {
  isOpen: boolean
  cancel: () => void
  closeChat: () => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    closeChatContainer: {
      background: "#FFFFFF",
      padding: theme.spacing(5, 15.75),
      width: "fit-content",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      rowGap: theme.spacing(3),
      borderRadius: theme.spacing(1.5),
    },
    closeChatTitle: {
      fontSize: theme.spacing(3),
      color: "#485359",
      fontFamily: typography.circularXXMedium.fontFamily,
    },
    closeChatDescription: {
      fontSize: theme.spacing(2),
      color: "#677278",
      fontFamily: typography.circularXXMedium.fontFamily,
      width: theme.spacing(51.6),
      textAlign: "center",
    },
    closeChatButtonContainer: {
      display: "flex",
      flexDirection: "row",
      columnGap: theme.spacing(2),
    },
    cancelButtonLabel: {
      fontSize: theme.spacing(2),
      color: "#1F70CE",
      fontFamily: typography.circularXXMedium.fontFamily,
    },
    closeButtonLabel: {
      fontSize: theme.spacing(2),
      color: "#F7F5FE",
      fontFamily: typography.circularXXMedium.fontFamily,
    },
    cancelButton: {
      height: theme.spacing(6),
      width: theme.spacing(15),
      boxShadow: "none",
    },
    closeButton: {
      height: theme.spacing(6),
      width: theme.spacing(15),
      boxShadow: "none",
      backgroundColor: "#1F70CE",
      padding: theme.spacing(2),
    },
  }),
)

const CloseChatModal = ({ isOpen, cancel, closeChat }: CloseChatModalProps) => {
  const classes = useStyles()

  return (
    <Modal
      open={isOpen}
      disablePortal
      className={classes.modal}
      aria-labelledby="close-chat-modal-label"
      aria-describedby="close-chat-modal-description">
      <Box className={classes.closeChatContainer}>
        <Typography className={classes.closeChatTitle}>
          Close this chat?
        </Typography>

        <Typography className={classes.closeChatDescription}>
          Once this chat is closed, the member cannot engage with this chat
          anymore. Please confirm with the member before closing.
        </Typography>

        <Stack className={classes.closeChatButtonContainer}>
          <Button
            className={classes.cancelButton}
            variant="outlined"
            onClick={cancel}>
            <Typography className={classes.cancelButtonLabel}>
              Cancel
            </Typography>
          </Button>

          <Button
            className={classes.closeButton}
            variant="contained"
            onClick={closeChat}>
            <Typography className={classes.closeButtonLabel}>
              Close Chat
            </Typography>
          </Button>
        </Stack>
      </Box>
    </Modal>
  )
}

export { CloseChatModal }
