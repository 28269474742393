import React, { useRef, useState, useEffect } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { Paper, Theme } from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { observer } from "mobx-react-lite"
import { trim } from "ramda"
import clsx from "clsx"

import { RouteParams } from "../../AppRoutes"
import { useStores } from "../../models/root-store"
import { MslEvent } from "../../models/msl-event"
import { ChatMessage } from "../../models/chat-message"
import { ChatType } from "../../models/chat-message"
import { ChatBubbleGroup } from "../chat-bubble-group"
import { InsertChatContent } from "../insert-chat-content-modal"
import { ChatHeader } from "./chat-header"
import { ChatInput } from "./chat-input"
import { ChatBubble } from "./chat-bubble"
import { EmptyRoom } from "./empty-room"

export const CHAT_REFRESH_RATE = 2000

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chatComponent: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      flex: 1,
      border: 0,
      alignItems: "center",
    },
    chatContent: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      flex: 1,
      alignItems: "center",
      padding: theme.spacing(2, 3, 0, 3),
    },
    chatListContainer: {
      display: "flex",
      justifyContent: "center",
      flex: "1 1 1px",
      overflow: "hidden",
      position: "relative",
      width: "100%",
    },
    chatWrapperStyle: {
      padding: theme.spacing(2, 0, 0, 0),
    },
    sliderChatWrapperStyle: {
      backgroundColor: "#F8FAFA",
    },

    sliderChatListContainer: {
      backgroundColor: "#F8FAFA",
      border: "1px solid #B0BABF",
      padding: theme.spacing(4.25, 3),
      borderRadius: theme.spacing(1.5),
      margin: theme.spacing(2.13),
    },

    emptyState: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      background: "#F1F1F1",
    },
  }),
)

interface AffinityChatRouteParams extends RouteParams {
  groupId: string
  coachId: string
  affinityGroupId: string
}

interface AffinityChatComponentParams {
  messages?: ChatMessage[]
  isLoading?: boolean
  isSlider?: boolean
  hideInputComponent?: boolean
  sendMessage?: (messageData: {
    message: string
    type?: string
    meta?: string
  }) => void
}

interface LocationState {
  backgroundScreen?: string
}

export const AffinityChatComponent = observer(
  ({
    messages = [],
    sendMessage,
    isSlider,
    isLoading,
    hideInputComponent,
  }: AffinityChatComponentParams) => {
    const classes = useStyles()
    const params = useParams<AffinityChatRouteParams>()

    const location = useLocation<LocationState>()
    const isParticipantDetailsBackground = location.state?.backgroundScreen

    const { pilotTrainerStore, loginStore } = useStores()
    const [message, setMessage] = useState<string>("")
    const [
      showInsertContentModal,
      setShowInsertContentModal,
    ] = useState<boolean>(false)
    const [submitted, setSubmitted] = useState<boolean>(false)
    const [
      isLoadingSendMessage,
      toggleIsLoadingSendMessage,
    ] = useState<boolean>(false)
    const [inAppContent, setInAppContent] = useState<MslEvent>()

    const {
      trainEpisodesPagination,
      affinityChatSessionsPagination,
    } = pilotTrainerStore
    const { person } = loginStore
    const {
      currentSession,
      apiCheckForNewAffinitySessions,
      removeClosedSession,
      resetCurrentSession,
    } = affinityChatSessionsPagination

    const inputRef = useRef(null)

    const history = useHistory()
    const goBack = () => history.goBack()

    // Focus the text input when chat room changes.
    useEffect(() => {
      inputRef?.current?.focus()
    }, [])

    const trimmedMessage = trim(message)

    const handleMessageChange = (
      event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) => {
      setMessage(event.target.value)
      if (submitted) {
        setSubmitted(false)
      }
    }

    const openInsertContentModal = () => {
      trainEpisodesPagination.reset()
      setShowInsertContentModal(true)
    }

    const closeInsertContentModal = () => {
      setShowInsertContentModal(false)
    }

    const insertInAppContent = (newContent: MslEvent) => {
      setInAppContent(newContent)
    }

    const removeInAppContent = () => {
      setInAppContent(null)
    }

    const handleSendMessage = async () => {
      if (!!!trimmedMessage.length) {
        setSubmitted(true)
        return
      }
      try {
        toggleIsLoadingSendMessage(true)
        await sendMessage({
          message,
          meta: inAppContent?.id,
          type: inAppContent ? ChatType.Train : ChatType.Message,
        })
        await apiCheckForNewAffinitySessions({
          groupID: params.affinityGroupId,
        })
        setMessage("")
        setInAppContent(null)
        toggleIsLoadingSendMessage(false)
      } catch (error) {
        toggleIsLoadingSendMessage(false)
      }
    }

    const handleCloseChat = async () => {
      try {
        await sendMessage({
          message: "Close chat",
          meta: inAppContent?.id,
          type: ChatType.Direct_Chat_Ended,
        })
        removeClosedSession(currentSession.id)
      } catch (e) {
        console.log(e)
      }
    }

    const handleModalClose = () => {
      goBack()
      resetCurrentSession()
    }

    const _rowRenderer = (message: ChatMessage) => {
      const isMessageFromCurrentPerson = person.id === message.author.id
      const isMessageFromCoach = message?.author?.isCoach

      return (
        <ChatBubble
          key={message.id}
          message={message}
          isMessageFromCurrentPerson={isMessageFromCurrentPerson}
          isMessageFromCoach={isMessageFromCoach}
          showAuthorName
        />
      )
    }

    return (
      <>
        <Paper variant="outlined" className={classes.chatComponent}>
          <div className={classes.chatContent}>
            <ChatHeader
              isSlider={isSlider}
              showViewProfileButton={!isParticipantDetailsBackground}
              handleModalClose={handleModalClose}
              handleCloseChat={handleCloseChat}
            />

            <div
              className={clsx(classes.chatListContainer, {
                [classes.sliderChatListContainer]: isSlider,
              })}>
              <ChatBubbleGroup
                messages={messages}
                isEndReached={true}
                isPaginating={false}
                isLoading={isLoading}
                handleScrollPagination={() => {}}
                handleDeleteMessage={() => {}}
                handleRenderRow={_rowRenderer}
                wrapperStyle={clsx(classes.chatWrapperStyle, {
                  [classes.sliderChatWrapperStyle]: isSlider,
                })}
                EmptyComponent={
                  <EmptyRoom
                    title="Send a Chat"
                    subtitle={
                      <>
                        Send a chat to begin a 1:1 intervention. Use the text
                        box <br /> below to draft and send your first message.
                      </>
                    }
                  />
                }
              />
            </div>
          </div>

          {!hideInputComponent && (
            <ChatInput
              inputRef={inputRef}
              value={message}
              onChange={handleMessageChange}
              isLoading={isLoadingSendMessage}
              showError={submitted && message.trim().length === 0}
              sendMessage={handleSendMessage}
              inAppContent={inAppContent}
              insertInAppContent={openInsertContentModal}
              removeInAppContent={removeInAppContent}
              coachAvatar={person.profile.avatarImageURL}
              disabledSendButton={message.trim().length === 0}
            />
          )}

          <InsertChatContent
            open={showInsertContentModal}
            onClose={closeInsertContentModal}
            submitContent={insertInAppContent}
          />
        </Paper>
      </>
    )
  },
)
