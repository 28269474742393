import { types, Instance, SnapshotOut, cast, clone } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../extensions"
import { ChatMessageLog } from "../chat-message/chat-message-log"
import { Person, PersonModel, PersonSnapshot } from "../person/person"
import { Tri } from "../tri"
import { Note } from "../note"
import { isObservableObject } from "mobx"
import { Attendance } from "../attendance"
import {
  VerifyPersonLogsParams,
  CreateParticipantNoteParams,
  UpdateParticipantNoteParams,
  DeleteParticipantNoteParams,
} from "../../services/api/api.types"
import { replaceObjectById } from "../../utils/replace-object-in-array"
import { removeObjectById } from "../../utils/remove-object-from-array"

const VERSION = 1

/******************************************************************
 *
 *
 * PersonStore Model
 *
 *
 * ***************************************************************/
export const PersonStoreModel = types
  .model("PersonStore")
  .extend(withEnvironment)
  .extend(withRootStore)
  .props({
    version: VERSION,
    currentPerson: types.optional(PersonModel, () =>
      PersonModel.create({ id: "-1" }),
    ),
    avatarsList: types.optional(types.array(types.string), []),
  })
  .actions((self) => ({
    setCurrentPerson(value: Person | PersonSnapshot) {
      self.currentPerson = cast(
        isObservableObject(value) ? clone(value as Person) : value,
      )
    },
    appendPersonLogs(logs: any) {
      self.currentPerson.appendLogs(logs as Array<ChatMessageLog>)
    },
    setPersonAttendances(value: Array<Attendance>) {
      self.currentPerson.setAttendances(value)
    },
    appendPersonTris(value: Array<Tri>) {
      self.currentPerson.appendTris(value)
    },
    setAvatarsList(value: Array<string>) {
      self.avatarsList.replace(value)
    },
  }))
  .actions((self) => ({
    reset() {
      self.currentPerson = PersonModel.create({ id: "-1" })
    },
  }))

  .actions((self) => ({
    async apiGetParticipantInCohort(cohortId: string, personId: string) {
      const { environment } = self
      const { api } = environment

      const { data, kind } = await api.getParticipantInCohort({
        cohortId,
        participantId: personId,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.setCurrentPerson(data as Person)
    },
    async apiGetPersonLogs(
      cohortId: string,
      participantId: string,
    ): Promise<void> {
      const { environment } = self
      const { api } = environment

      const { data, kind } = await api.getPersonLogs({
        participantId,
        cohortId,
      })

      const logsData = data as any

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.currentPerson.setTotalWeightLossValue(logsData.meta.weight.totalLoss)
      self.currentPerson.setTotalWeightLossUnit(logsData.meta.weight.unit)
      self.currentPerson.setTotalExerciseMinutesValue(
        logsData.meta.exercise.totalActivity,
      )
      self.currentPerson.setTotalExerciseMinutesUnit(
        logsData.meta.exercise.unit,
      )
      self.appendPersonLogs(logsData.logs)
    },
    async apiVerifyPersonLogs({ logId }: VerifyPersonLogsParams) {
      const { environment } = self
      const { api } = environment

      const { data, kind } = await api.verifyPersonLog({ logId })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const personLog = self.currentPerson.logs.find((log) => log.id === logId)
      personLog.setVerified(true)
    },
    async apiDeleteVerifyPersonLogs({ logId }: VerifyPersonLogsParams) {
      const { environment } = self
      const { api } = environment

      const { data, kind } = await api.deleteVerifyPersonLog({ logId })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      const personLog = self.currentPerson.logs.find((log) => log.id === logId)
      personLog.setVerified(false)
    },
    async apiGetParticipantTriHistory(
      cohortId: string,
      participantId: string,
    ): Promise<void> {
      const { environment } = self
      const { api } = environment

      const { data, kind } = await api.getParticipantTriHistory({
        participantId,
        cohortId,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      self.appendPersonTris(data as Array<Tri>)
    },

    async apiGetParticipantAttendanceHistory(
      cohortId: string,
      participantId: string,
    ): Promise<void> {
      const { environment, rootStore } = self
      const { siteStore } = rootStore
      const { api } = environment

      const { data, kind } = await api.getParticipantAttendanceHistory({
        participantId,
        cohortId,
        siteId: siteStore.currentSiteId,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const attendances = data as Array<Attendance>

      self.setPersonAttendances(attendances)
    },
    async apiGetAvatarsList() {
      const { environment } = self
      const { api } = environment

      const { data, kind } = await api.getAvatarsList()

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.setAvatarsList((data as { avatars: string[] }).avatars)
    },
    async apiGetParticipantDetails(participantID: string): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.getParticipantDetails({ participantID })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.setCurrentPerson(data)
    },
    async apiGetParticipantTriHistoryNonDpp(
      participantID: string,
    ): Promise<void> {
      const { environment } = self
      const { api } = environment

      const { data, kind } = await api.getParticipantTriHistoryNonDpp({
        participantID,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      self.currentPerson.appendTris(data)
    },
    async apiGetParticipantBiometrics(participantID: string): Promise<void> {
      const { environment } = self
      const { api } = environment

      const { data, kind } = await api.getParticipantBiometrics({
        participantID,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      self.currentPerson.setBiometrics(data)
    },
    async apiGetParticipantConditions(participantID: string): Promise<void> {
      const { environment } = self
      const { api } = environment

      const { data, kind } = await api.getParticipantConditions({
        participantID,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      self.currentPerson.setConditions(data)
    },
    async apiGetParticipantNotes(participantId: string): Promise<void> {
      const { environment } = self
      const { api } = environment

      const { data, kind } = await api.getParticipantNotes({
        participantId,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      self.currentPerson.setNotes(data)
    },
    async apiGetAffinityMemberNotes({ groupID, memberID }) {
      const { api } = self.environment
      const { kind, data } = await api.getAffinityMemnberNotes({
        groupID: groupID,
        memberID: memberID,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.currentPerson.setNotes(data.data)
    },
    async apiCreateAffinityParticipantNotes({ groupID, memberID, note }) {
      const { api } = self.environment

      const { kind, data } = await api.createAffinityMemberNote({
        groupID,
        memberID,
        note,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const newNotesList = [data, ...self.currentPerson.notes]

      self.currentPerson.setNotes(newNotesList)
    },
    async apiCreateParticipantNotes({
      groupID,
      participantID,
      noteText,
    }: CreateParticipantNoteParams) {
      const { api } = self.environment

      const { kind, data } = await api.createParticipantNote({
        groupID,
        participantID,
        noteText,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const newNotesList = [data, ...self.currentPerson.notes]

      self.currentPerson.setNotes(newNotesList)
    },
    async apiUpdateParticipantNotes({
      groupID,
      participantID,
      noteText,
      id,
    }: UpdateParticipantNoteParams) {
      const { api } = self.environment
      const { kind, data } = await api.updateParticipantNote({
        groupID,
        participantID,
        noteText,
        id,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const newNotesList = replaceObjectById<Note>(
        self.currentPerson.notes,
        data,
      )

      self.currentPerson.setNotes(newNotesList)
    },
    async apiDeleteParticipantNote({
      id,
      groupId,
      participantID,
    }: DeleteParticipantNoteParams) {
      const { environment } = self
      const { api } = environment

      const { data, kind } = await api.deleteParticipantNote({
        id,
        groupId,
        participantID,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const newNotesList = removeObjectById<Note>(self.currentPerson.notes, id)

      self.currentPerson.setNotes(newNotesList)
    },
    async apiGetParticipantProgress(participantID: string): Promise<void> {
      const { environment } = self
      const { api } = environment

      const { data, kind } = await api.getParticipantProgress({
        participantID,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      self.currentPerson.setBadges(data.badges || [])
      self.currentPerson.setPbcScores(data.pbcScores || {})
      self.currentPerson.setSrbaiScores(data.srbaiScores || {})
    },
    async apiGetParticipantMeta(participantID: string): Promise<void> {
      const { environment } = self
      const { api } = environment

      const { data, kind } = await api.getParticipantMeta({
        participantID,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      self.currentPerson.setSegmentation(data.segmentation)
      self.currentPerson.setEmployer(data.employer)
      self.currentPerson.setReasonForBeingHere(data.reasonForBeingHere)
    },
    async apiGetAffinityParticipantChatHistory(
      participantID: string,
      groupID: string,
    ): Promise<void> {
      const { environment } = self
      const { api } = environment

      const { data, kind } = await api.getAffinityMemnberChatHistory({
        participantID,
        groupID,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.currentPerson.setChatHistory(data.data)
    },
  }))

export interface PersonStore extends Instance<typeof PersonStoreModel> {}
export interface PersonStoreSnapshot
  extends SnapshotOut<typeof PersonStoreModel> {}
