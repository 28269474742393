import { Button, InputAdornment, List } from "@material-ui/core"
import { useSnackbars } from "../use-snackbar"
import { useStores } from "../../models/root-store"
import { ModalMode } from "./create-tri-product-modal"
import { FieldTypes } from "../admin-config-form"
import { useFormState } from "../admin-config-form/useFormState"
import { TriProduct } from "../../models/tri-product"
import { BackupProductItem } from "./backup-product-item"

const useTriProductForm = ({
  isModalOpen,
  mode,
  closeModal,
}: {
  isModalOpen: boolean
  mode: ModalMode
  closeModal: () => void
}) => {
  const { setAndShowSnackbar } = useSnackbars()

  const { adminMslEventStoreModel } = useStores()
  const { triProductsPagination } = adminMslEventStoreModel

  const {
    newTriProduct,
    apiAdminGetSingleTriProduct,
    apiAdminCreateTriProduct,
    apiAdminEditTriProduct,
    apiAdminRemoveBackupProduct,
    resetNewTriProduct,
    setNestedriProduct,
  } = triProductsPagination

  const {
    isBackup,
    type,
    backups,
    mainProductId,
    setType,
    createTriProductErrors: errors,
  } = newTriProduct

  const { validateErrors, loading, setLoading, submitted } = useFormState({
    isModalOpen,
    errors,
  })

  const onCancel = () => {
    resetNewTriProduct()

    closeModal()
  }

  const onSubmit = async () => {
    const isValid = validateErrors()
    if (!isValid) {
      return
    }

    try {
      setLoading(true)

      if (mode === ModalMode.CREATE) {
        await apiAdminCreateTriProduct()
        setAndShowSnackbar({
          text: "Successfully created product !",
          severity: "success",
        })
      } else {
        await apiAdminEditTriProduct({ isNestedProduct: false })
        setAndShowSnackbar({
          text: "Successfully edited product !",
          severity: "success",
        })
      }

      closeModal()
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    } finally {
      setLoading(false)
    }
  }

  const handleTypeChange = (
    event: React.SyntheticEvent<Element, Event>,
    value,
  ) => {
    setType(value.value)
  }

  const isMainProductWithBackups = !isBackup && backups.length > 0

  const handleBackup = async (backupProduct?: TriProduct) => {
    if (mode === ModalMode.EDIT) {
      if (mainProductId) {
        await apiAdminGetSingleTriProduct({
          productID: mainProductId,
          isNestedProduct: true,
        })
      } else {
        setNestedriProduct(backupProduct)
      }
    }
  }

  const handleRemoveBackup = async (backup: TriProduct) => {
    try {
      await apiAdminRemoveBackupProduct({
        ...backup,
        mainProductId: null,
        backupSequence: null,
        isBackup: false,
      })
      setAndShowSnackbar({
        text: "Successfully removed !",
        severity: "success",
      })
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    }
  }

  const triProductFormConfig = [
    {
      fieldName: "id",
      label: "ID",
      showOnlyOnEditMode: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        disabled: true,
      },
    },
    {
      fieldName: "name",
      onChangeMethod: "setName",
      label: "Name",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter name",
      },
    },
    {
      fieldName: "displayName",
      onChangeMethod: "setDisplayName",
      label: "Display Name",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter display name",
      },
    },
    {
      fieldName: "description",
      onChangeMethod: "setDescription",
      label: "Description",
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter description",
      },
    },
    {
      fieldName: "upc",
      onChangeMethod: "setUPC",
      label: "UPC",
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter upc",
      },
    },
    {
      fieldName: "WMProductID",
      onChangeMethod: "setWmProductID",
      label: "WM Product ID",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter WM Product ID",
      },
    },
    {
      fieldName: "imageURL",
      onChangeMethod: "setImageURL",
      label: "Image URL",
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter Image URL",
      },
    },
    {
      fieldName: "retailer",
      onChangeMethod: "setRetailer",
      label: "Retailer",
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter retailer",
      },
    },
    {
      fieldName: "type",
      label: "Type",
      required: true,
      fieldType: FieldTypes.Select,
      componentProps: {
        disableClearable: true,
        options: [{ id: "Snack", value: "snack" }],
        value: type ? { id: type, value: type } : null,
        renderOption: (props, option: { id: string }) => (
          <li {...props} key={option.id}>
            {option.id}
          </li>
        ),
        getOptionLabel: (option) => {
          return option.id
        },
        onChange: handleTypeChange,
        Input: {
          placeholder: "Select Type",
        },
      },
    },
    {
      fieldName: "isBackup",
      onChangeMethod: "setIsBackup",
      label: "Is backup ?",
      fieldType: FieldTypes.Switch,
    },
    {
      ...(isBackup && {
        fieldName: "backupSequence",
        onChangeMethod: "setBackupSequence",
        label: "Backup sequence",
        fieldType: FieldTypes.TextInput,
        required: true,
        componentProps: {
          placeholder: "Enter backup sequence",
          type: "number",
        },
      }),
    },
    {
      ...(isBackup && {
        fieldName: "mainProductId",
        onChangeMethod: "setMainProductID",
        label: "Main product ID",
        required: true,
        fieldType: FieldTypes.TextInput,
        componentProps: {
          placeholder: "Enter main product ID",
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!Boolean(mainProductId)}
                  onClick={() => handleBackup()}>
                  View
                </Button>
              </InputAdornment>
            ),
          },
        },
      }),
    },
    {
      ...(isMainProductWithBackups && {
        fieldName: "back",
        onChangeMethod: "",
        label: "Backup products",
        fieldType: FieldTypes.Select,
        componentProps: {
          id: "backupProductsList",
          disabled: true,
          options: [],
          value: null,
          selectedOptionContent: (
            <List>
              {backups.slice().map((backup: TriProduct) => (
                <BackupProductItem
                  key={backup.id}
                  backup={backup}
                  onClick={() => handleBackup(backup)}
                  handleRemoveBackup={() => handleRemoveBackup(backup)}
                />
              ))}
            </List>
          ),
        },
        showOnlyOnEditMode: true,
      }),
    },
  ]

  return {
    newTriProduct,
    loading,
    submitted,
    onCancel,
    onSubmit,
    values: newTriProduct,
    errors,
    triProductFormConfig,
  }
}

export { useTriProductForm }
