import React from "react"
import { useRouteMatch } from "react-router-dom"

import { observer } from "mobx-react-lite"
import { Theme } from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"

import { useStores } from "../../models/root-store"
import { AffinityTab } from "./affinity-tab"
import { useInterval } from "../use-interval"
interface RouteParams {
  affinityGroupId: string
  coachId: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      width: "100%",
      background: theme.palette.common.white,
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(1.5, 0, 0, 3),
      borderBottom: "1px solid #C7CFD4",
    },
  }),
)

const AffinityTabsBar = observer(() => {
  const classes = useStyles()
  const { pilotTrainerStore } = useStores()
  const { affinityChatSessionsPagination } = pilotTrainerStore
  const {
    apiCheckForNewAffinitySessions,
    coachHasOpenMessages,
  } = affinityChatSessionsPagination

  const matchAffinityRoute = useRouteMatch<RouteParams>(
    "/pilot-trainer/:coachId/affinity/:affinityGroupId",
  )

  const isPersonDetailsView = useRouteMatch<RouteParams>(
    "/pilot-trainer/:coachId/affinity/:affinityGroupId/affinity-members/:personId",
  )?.isExact

  const { affinityGroupId } = matchAffinityRoute?.params || {}

  const fetchOpenChats = async () => {
    try {
      await apiCheckForNewAffinitySessions({
        groupID: affinityGroupId,
      })
    } catch (e) {
      console.log(e)
    }
  }

  useInterval(fetchOpenChats, 10000)

  if (!affinityGroupId || isPersonDetailsView) {
    return <div />
  }

  return (
    <div className={classes.content}>
      <AffinityTab route="/feed" label="Main" />

      <AffinityTab
        route="/chats"
        label="Chats"
        hasOpenChats={coachHasOpenMessages}
      />

      <AffinityTab route="/members" label="Members" />
    </div>
  )
})

export { AffinityTabsBar }
