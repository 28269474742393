import React from "react"
import { Dialog } from "@material-ui/core"

import { Header } from "./modal-header"
import { ContentList } from "./modal-content-list"
import { Footer } from "./modal-footer"
import { ChatContentProvider, ChatContentContextProps } from "./contentContext"

interface InsertChatContentProps extends Partial<ChatContentContextProps> {
  open: boolean
}

const InsertChatContent = ({
  open,
  submitContent,
  onClose,
}: InsertChatContentProps) => {
  if (!open) {
    return <div />
  }
  return (
    <ChatContentProvider initialValues={{ onClose, submitContent }}>
      <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
        <Header />

        <ContentList />

        <Footer />
      </Dialog>
    </ChatContentProvider>
  )
}

export { InsertChatContent }
