import React from "react"
import {
  IconButton,
  FormControl,
  OutlinedInput,
  OutlinedInputProps,
  Theme,
  CircularProgress,
  Box,
  Stack,
  Typography,
} from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined"
import AddIcon from "@mui/icons-material/Add"
import clsx from "clsx"
import SendIcon from "@mui/icons-material/Send"

import { MslEvent } from "../../models/msl-event"
import { ShortText } from "../admin-form-components"
import { typography } from "../../services/theme/typography"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    messageInputWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      padding: theme.spacing(3),
      background: theme.palette.common.white,
      boxShadow: "0px -4px 6px rgba(45, 53, 59, 0.08)",
    },
    messageInputRoot: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      flex: 1,
      background: "#F0F3F7",
      borderRadius: theme.spacing(1),
      padding: theme.spacing(2),
      gap: theme.spacing(2),
    },
    insertContentButton: {
      margin: 0,
      padding: 0,
      width: theme.spacing(3),
      height: theme.spacing(3),
      background: "#D9D9D9",
    },
    addIcon: {
      fontSize: theme.spacing(3),
      color: "#1F70CE",
    },
    inputContainer: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
    },
    messageInput: {
      display: "flex",
      flex: 1,
      padding: 0,
      fontSize: theme.spacing(2),
      alignItems: "flex-start",
      "& .MuiInputBase-input::placeholder": {
        opacity: 1,
        color: "#677278",
        fontSize: theme.spacing(2),
        fontFamily: typography.circularXXMedium.fontFamily,
      },
    },
    messageInputNotchedOutline: {
      border: "0px solid !important",
    },
    inputError: {
      fontSize: theme.spacing(1.5),
      color: theme.palette.error.main,
    },
    sendButton: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      color: "#3B85CC",
      textTransform: "none",
      padding: theme.spacing(0.75),
      borderRadius: theme.spacing(1),
      background: "#1771A6",
      "&:hover": {
        background: "#1771A6",
      },
      "&.Mui-disabled": {
        background: "rgba(58, 58, 58, 0.4)",
        color: "#fff",
      },
    },
    sendIcon: {
      fill: theme.palette.common.white,
      fontSize: `${theme.spacing(1.5)} !important`,
    },
    trainTile: {
      minWidth: 180,
      border: "1px solid #546e7a",
      borderRadius: theme.spacing(0.5),
      position: "relative",
      padding: theme.spacing(1),
      alignSelf: "flex-start",
      marginTop: theme.spacing(2),
    },
    primaryText: {
      fontSize: theme.spacing(2),
      color: "#485359",
      wordBreak: "break-all",
      ...typography.circularXXBold,
    },
    secondaryText: {
      fontSize: theme.spacing(1.75),
      color: "#485359",
      wordBreak: "break-all",
      ...typography.circularXXBook,
    },
    cancelButton: {
      position: "absolute",
      right: -8,
      top: -8,
      margin: 0,
      padding: 0,
      "& svg": {
        background: theme.palette.common.white,
        fontSize: theme.spacing(2.5),
      },
    },
    coachAvatar: {
      width: theme.spacing(3.75),
      height: theme.spacing(3.75),
      borderRadius: "50%",
      border: "1.5px solid #fff",
      filter: "drop-shadow(0px 2.02146px 6.06438px rgba(0, 0, 0, 0.12))",
    },
  }),
)

interface ChatInputProps extends OutlinedInputProps {
  hideInput?: boolean
  disabledSendButton?: boolean
  isLoading?: boolean
  showError?: boolean
  inAppContent?: MslEvent
  insertInAppContent?: () => void
  removeInAppContent?: () => void
  sendMessage: () => void
  coachAvatar: string
}

export const ChatInput = ({
  hideInput,
  disabledSendButton,
  isLoading,
  showError,
  inAppContent,
  insertInAppContent,
  removeInAppContent,
  sendMessage,
  coachAvatar,
  ...inputProps
}: ChatInputProps) => {
  const classes = useStyles()

  //Handle shortcut for sending new message when the user clicks CTRL(Control on OSX)+Enter
  const keydownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (disabledSendButton || isLoading) {
      return
    }
    if (e.key === "Enter" && e.ctrlKey) {
      sendMessage()
    }
  }

  if (hideInput) {
    return <div />
  }

  return (
    <FormControl fullWidth className={clsx(classes.messageInputWrapper)}>
      <div className={classes.messageInputRoot}>
        <IconButton
          className={classes.insertContentButton}
          onClick={insertInAppContent}>
          <AddIcon className={classes.addIcon} />
        </IconButton>

        <img
          src={coachAvatar}
          className={classes.coachAvatar}
          alt="Person Avatar"
        />

        <Box className={classes.inputContainer}>
          <Stack spacing={0.5}>
            <OutlinedInput
              {...inputProps}
              id="chat-input"
              type="text"
              placeholder="Type Message..."
              autoFocus
              multiline={true}
              minRows={1}
              maxRows={10}
              className={classes.messageInput}
              classes={{
                notchedOutline: classes.messageInputNotchedOutline,
              }}
              onKeyUp={keydownHandler}
            />

            {showError && (
              <div className={classes.inputError}>*Please enter a message!</div>
            )}
          </Stack>

          {inAppContent && (
            <Stack className={classes.trainTile}>
              <Typography className={classes.primaryText}>
                <ShortText value={inAppContent.name} textLimit={20} />
              </Typography>

              <Typography className={classes.secondaryText}>
                TRAIN EPISODE
              </Typography>

              <IconButton
                className={classes.cancelButton}
                onClick={removeInAppContent}>
                <CancelOutlinedIcon />
              </IconButton>
            </Stack>
          )}
        </Box>

        <IconButton
          color="primary"
          onClick={sendMessage}
          disabled={disabledSendButton || isLoading}
          className={classes.sendButton}>
          {isLoading ? (
            <CircularProgress color="secondary" size={12} />
          ) : (
            <SendIcon className={classes.sendIcon} />
          )}
        </IconButton>
      </div>
    </FormControl>
  )
}
