import React, { useRef, useState } from "react"
import {
  Typography,
  Tabs,
  Tab,
  Theme,
  Stack,
  FilledInput,
  CircularProgress,
} from "@material-ui/core"
import { debounce } from "rambdax"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"

import { useStores } from "../../models/root-store"
import { useSnackbars } from "../use-snackbar"
import { typography } from "../../services/theme/typography"
import { ContentTabs, useChatContent } from "./contentContext"

const THROTTLE_DELAY = 2000

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    headerContainer: {
      padding: theme.spacing(4, 4, 0, 4),
    },
    headerLabel: {
      fontSize: theme.spacing(3.25),
      color: "#485359",
      textAlign: "center",
      ...typography.circularXXBold,
    },
    search: {
      marginTop: theme.spacing(2),
      width: "100%",
    },
  }),
)

const Header = () => {
  const [loading, setLoading] = useState<boolean>(false)

  const classes = useStyles()
  const {
    activeTab,
    setActiveTab,
    searchTerm,
    setSearchTerm,
    isSearchActive,
    setSearchCompleted,
  } = useChatContent()
  const { setAndShowSnackbar } = useSnackbars()
  const { pilotTrainerStore } = useStores()

  const { apiPilotCoachSearchTrainContent } = pilotTrainerStore

  /**
   * Debounced method for search api, to avoid api calls while user is writing in the search field
   * The api call will be fired 2 seconds after the last debounced method
   */
  const debouncedSearchTrainContent = useRef(
    debounce(async (query: string) => {
      try {
        //avoid firing api call with empty input
        if (query.trim().length) {
          await apiPilotCoachSearchTrainContent({ query })
        }
        setLoading(false)
        setSearchCompleted(true)
      } catch (error) {
        setLoading(false)
        setAndShowSnackbar({ text: error.message, severity: "error" })
      }
    }, THROTTLE_DELAY),
  )

  /**
   * Search Input `onChange` handler
   */
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
    setLoading(true)
    setSearchCompleted(false)

    //call debounced method on every input change
    debouncedSearchTrainContent.current(event.target.value)
  }

  return (
    <Stack className={classes.headerContainer}>
      <Typography className={classes.headerLabel}>Insert Content</Typography>
      <Tabs
        value={activeTab}
        onChange={(_, newValue: ContentTabs) => {
          setActiveTab(newValue)
        }}
        aria-label="tabs"
        textColor="primary">
        <Tab label="Train" value={ContentTabs.TRAIN} />

        <Tab label="Tab 2" value={ContentTabs.TAB2} />

        <Tab label="Tab 3" value={ContentTabs.TAB3} />
      </Tabs>

      <FilledInput
        id="search"
        placeholder="Search Content"
        className={classes.search}
        value={searchTerm}
        onChange={onInputChange}
        endAdornment={
          /**
           * While api call is loading, show the progress component
           */
          isSearchActive && loading && <CircularProgress size={20} />
        }
      />
    </Stack>
  )
}

export { Header }
