import { types, Instance, SnapshotOut, clone } from "mobx-state-tree"
import * as customTypes from "../types"
import { validate, ValidationRules } from "../../utils/validate"

export const TriProductModel = types
  .model("TriProduct")
  .props({
    id: types.identifier,
    name: types.optional(types.maybeNull(types.string), null),
    displayName: types.optional(types.maybeNull(types.string), null),
    description: types.optional(types.maybeNull(types.string), null),
    backups: types.optional(types.array(types.late(() => TriProductModel)), []),
    upc: types.maybeNull(types.string),
    retailer: types.optional(types.maybeNull(types.string), null),
    WMProductID: types.optional(types.maybeNull(types.number), null),
    imageURL: types.optional(types.maybeNull(types.string), null),
    type: types.optional(types.maybeNull(types.string), null),
    isBackup: types.optional(types.boolean, false),
    backupSequence: types.optional(types.maybeNull(types.number), null),
    mainProductId: types.optional(types.maybeNull(types.string), null),
    isStale: types.optional(types.maybeNull(types.boolean), false),
    createdAt: types.optional(customTypes.iso8601, null),
    updatedAt: types.optional(customTypes.iso8601, null),
  })
  .actions((self) => ({
    setName(value: string) {
      self.name = value
    },
    setDisplayName(value: string) {
      self.displayName = value
    },
    setDescription(value: string) {
      self.description = value
    },
    setUPC(value: string) {
      self.upc = value
    },
    setRetailer(value: string) {
      self.retailer = value
    },
    setWmProductID(value: string) {
      const numberValue = Number(value)

      if (!value.length) {
        self.WMProductID = null

        return
      }

      if (!isNaN(numberValue)) {
        self.WMProductID = numberValue
      }
    },
    setImageURL(value: string) {
      self.imageURL = value
    },
    setType(value: string) {
      self.type = value
    },
    setBackups(value: TriProduct[]) {
      self.backups.replace(value.map((e) => clone(e)))
    },
    setIsBackup(value: boolean) {
      self.isBackup = value
    },
    setBackupSequence(value: string) {
      self.backupSequence = Number(value)
    },
    setMainProductID(value: string) {
      self.mainProductId = value
    },

    reset() {
      self.id = "-1"
      self.name = ""
      self.displayName = ""
      self.description = ""
      self.type = ""
      self.upc = null
      self.WMProductID = null
      self.imageURL = ""
      self.type = ""
      self.retailer = ""
      self.isBackup = false
      self.isStale = false
      self.backupSequence = null
      self.mainProductId = ""
      self.createdAt = null
      self.updatedAt = null
      self.backups.clear()
    },
  }))
  .views((self) => ({
    get createTriProductErrors() {
      return validate(CREATE_NEW_TRI_PRODUCT_RULES, self)
    },
    get isEditMode() {
      return self.id !== "-1"
    },
  }))

const CREATE_NEW_TRI_PRODUCT_RULES: ValidationRules = {
  name: {
    presence: { allowEmpty: false, message: "required" },
  },
  displayName: {
    presence: { allowEmpty: false, message: "required" },
  },
  WMProductID: {
    presence: { allowEmpty: false, message: "required" },
  },
  type: {
    presence: { allowEmpty: false, message: "required" },
  },
  backupSequence: {
    validateFieldDependency: {
      compare: "isBackup",
      message: "Required",
    },
  },
  mainProductId: {
    validateFieldDependency: {
      compare: "isBackup",
      message: "Required",
    },
  },
}

export interface TriProduct extends Instance<typeof TriProductModel> {}
export interface TriProductSnapshot
  extends SnapshotOut<typeof TriProductModel> {}
