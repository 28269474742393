import { types, Instance, SnapshotOut, isAlive } from "mobx-state-tree"
import moment from "moment"

import { withEnvironment, withRootStore } from "../extensions"
import { PilotChatMeta, PilotChatMetaModel } from "../pilot-chat-meta"
import { Person, PersonModel } from "../person/person"
import { PaginationModel, MESSAGES_LIMIT } from "../pagination"
import { ChatMessage, sortByTheLastestMessage } from "../chat-message"
import { InboxItemType } from "../cohort"
import { InactiveParticipantsPaginationModel } from "../inactive-participants-pagination"
import { PilotCoachAddNewMessageParams } from "../../services/api/api.types"
import { AffinityGroup, AffinityGroupModel } from "../affinity-group"
import { AffinityGroupFeedPaginationModel } from "../affinity-group-feed-pagination"
import { AffinityGroupMembersPaginationModel } from "../affinity-group-members-pagination"
import { TrainPaginationModel } from "./train-pagination"
import { transformChatMeta, transformParticipantsList } from "./utils"
import { AffinityChatSessionsPaginationModel } from "../affinity-chat-sessions-pagination"

const VERSION = 1

export const PilotTrainerStoreModel = types
  .model("PilotTrainerStore")
  .extend(withEnvironment)
  .extend(withRootStore)
  .props({
    version: VERSION,
    coachNote: types.optional(types.string, ""),
    motivationImage: types.maybeNull(types.string),
    coachIsOnline: types.maybeNull(types.boolean),
    oneOneGroups: types.optional(types.array(AffinityGroupModel), []),
    affinityGroups: types.optional(types.array(AffinityGroupModel), []),
    currentRoomPagination: types.optional(PaginationModel, () =>
      PaginationModel.create({
        limit: MESSAGES_LIMIT,
        messages: [],
      }),
    ),
    trainEpisodesPagination: types.optional(TrainPaginationModel, () =>
      TrainPaginationModel.create(),
    ),
    currentGroupUnreadMessagesMeta: types.optional(PilotChatMetaModel, {}),
    currentGroupSavedChatsPagination: types.optional(PilotChatMetaModel, {}),
    currentGroupInactiveParticipantsPagination: types.optional(
      InactiveParticipantsPaginationModel,
      () => InactiveParticipantsPaginationModel.create(),
    ),
    currentGroupSearchParticipantsList: types.optional(
      types.array(PersonModel),
      [],
    ),
    affinityGroupFeedPagination: types.optional(
      AffinityGroupFeedPaginationModel,
      () => AffinityGroupFeedPaginationModel.create(),
    ),
    affinityGroupMembersPagination: types.optional(
      AffinityGroupMembersPaginationModel,
      () => AffinityGroupMembersPaginationModel.create(),
    ),
    affinityChatSessionsPagination: types.optional(
      AffinityChatSessionsPaginationModel,
      () => AffinityChatSessionsPaginationModel.create(),
    ),
  })
  .actions((self) => ({
    setCurrentCoachMeta(value: {
      coachNote: string | null
      motivationImage: string | null
      coachIsOnline: boolean | null
      oneOneGroups: AffinityGroup[]
      affinityGroups: AffinityGroup[]
    }) {
      self.coachNote = value.coachNote
      self.motivationImage = value.motivationImage
      self.coachIsOnline = value.coachIsOnline
      self.oneOneGroups.replace(value.oneOneGroups)
      self.affinityGroups.replace(value.affinityGroups)
    },
    setCoachNote(value: string) {
      self.coachNote = value
    },
    setMotivationImage(value: string) {
      self.motivationImage = value
    },
    setCoachIsOnline(value: boolean) {
      self.coachIsOnline = value
    },
    setCurrentGroupUnreadMessagesMeta(value: PilotChatMeta) {
      self.currentGroupUnreadMessagesMeta = value
    },
    setCurrentGroupSavedChatsPagination(value: PilotChatMeta) {
      self.currentGroupSavedChatsPagination = value
    },
    setSearchParticipants(value: Person[]) {
      self.currentGroupSearchParticipantsList.replace(value)
    },

    resetSearchParticipants() {
      if (isAlive(self.currentGroupSearchParticipantsList)) {
        self.currentGroupSearchParticipantsList.clear()
      }
    },
    reset() {
      self.currentGroupUnreadMessagesMeta.reset()
      self.trainEpisodesPagination.reset()
      self.coachNote = ""
      self.motivationImage = ""
      self.coachIsOnline = false
      self.oneOneGroups.replace([])
      self.affinityGroups.replace([])
      self.affinityGroupFeedPagination.resetPagination()
      self.affinityChatSessionsPagination.resetPagination()
    },
  }))
  .actions((self) => ({
    async apiGetCurrentCoachMeta(): Promise<void> {
      const { api } = self.environment
      const authenticatedCoach = self.rootStore.loginStore.person
      if (!authenticatedCoach?.profile?.coachID) {
        throw new Error("Coach not found!")
      }
      const { data, kind } = await api.getCurrentCoachMeta({
        coachID: authenticatedCoach.profile.coachID,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.setCurrentCoachMeta(data)
    },
    async apiCurrentGroupUnreadMessagesMeta(groupID: string): Promise<void> {
      const { api } = self.environment
      try {
        const { data, kind } = await api.getCurrentGroupUnreadMessagesMeta({
          groupID,
        })
        if (kind !== "ok") {
          //@ts-ignore
          throw new Error(data?.reason)
        }
        const transformedData = transformChatMeta(data[0])
        self.setCurrentGroupUnreadMessagesMeta(transformedData as PilotChatMeta)
      } catch (error) {
        throw error
      }
    },
    async apiGetCurrentGroupInactiveParticipants(
      groupID: string,
    ): Promise<void> {
      const { api } = self.environment
      const {
        order,
        limit,
        next,
        setNextPage,
        setParticipants,
      } = self.currentGroupInactiveParticipantsPagination
      const { data, kind } = await api.getCurrentGroupInactiveParticipants({
        groupID,
        orderBy: order,
        limit,
        next: next || undefined,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const isEndReached = data.participants.length < limit
      setParticipants(transformParticipantsList(data.participants))
      setNextPage({
        next: isEndReached ? null : data.next,
        isEndReached,
      })
    },
    async apiCurrentGroupSearchParticipants({
      groupID,
      query,
    }: {
      groupID: string
      query: string
    }): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.currentGroupSearchParticipants({
        groupID,
        query,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.setSearchParticipants(transformParticipantsList(data))
    },
    async apiGetCurrentGroupSavedChats(groupID: string): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.getCurrentGroupSavedChats({
        groupID,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const transformedData = transformChatMeta({
        newMessages: {},
        lastMessage:
          data?.lastMessage?.[self.rootStore.loginStore.person.id] || {},
        participantDetails: data?.participantDetails || {},
      })
      self.setCurrentGroupSavedChatsPagination(transformedData as PilotChatMeta)
    },
    async apiGetPilotChatConversation({
      roomId,
      coachID,
    }: {
      roomId: string
      coachID: string
    }): Promise<void> {
      const { environment, currentRoomPagination } = self
      const { api } = environment

      const { data, kind } = await api.getPilotChatConversation({
        roomId,
        coachID,
        limit: currentRoomPagination.limit,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      const messagesList = data.chats as ChatMessage[]
      currentRoomPagination.setChats(messagesList.reverse())
      const isEndReached = messagesList.length < currentRoomPagination?.limit
      currentRoomPagination.setIsEndReached(isEndReached)
    },
    async apiPilotCoachAddNewMessage(
      params: PilotCoachAddNewMessageParams,
    ): Promise<void> {
      const { environment } = self
      const { api } = environment
      const { data, kind } = await api.pilotCoachAddNewMessage(params)

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.currentRoomPagination.unshiftChat(data)
    },
    async apiPilotCoachDeleteMessage(messageId: string): Promise<void> {
      const { environment } = self
      const { api } = environment
      const { data, kind } = await api.pilotCoachDeleteMessage({
        messageId,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.currentRoomPagination.removeChat(messageId)
    },

    async apiPilotCoachSaveChat(chatMessage: ChatMessage): Promise<void> {
      const { environment } = self
      const { api } = environment
      const { data, kind } = await api.pilotCoachSaveChat({
        chatID: chatMessage.id,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const currentMessage = self.currentRoomPagination.messages.find(
        ({ id }) => id === chatMessage.id,
      )
      currentMessage?.setIsSavedChat?.(true)
    },
    async apiPilotCoachDeleteSavedChat(
      chatMessage: ChatMessage,
    ): Promise<void> {
      const { environment } = self
      const { api } = environment
      const { data, kind } = await api.pilotCoachDeleteChat({
        chatID: chatMessage.id,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const currentMessage = self.currentRoomPagination.messages.find(
        ({ id }) => id === chatMessage.id,
      )
      currentMessage?.setIsSavedChat?.(false)
    },
    async apiPilotCoachSearchTrainContent({
      query,
      next,
    }: {
      query: string
      next?: number
    }): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.pilotCoachSearchTrainContent({
        query,
        next,
        limit: self.trainEpisodesPagination.limit,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      //if paginating (next param is available), append episodes to the list
      if (next) {
        self.trainEpisodesPagination.addEpisodes(data.events)
      } else {
        self.trainEpisodesPagination.setEpisodes(data.events)
      }
      //if the result list is empty, it means the search is complete
      self.trainEpisodesPagination.setNext(
        data.events.length === 0 ? null : data.next,
      )
    },
    async apiUpdateCoachStatus(params: boolean): Promise<void> {
      const { environment } = self
      const { api } = environment
      const { data, kind } = await api.updateCoachStatus({
        online: params,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.setCoachIsOnline(params)
    },

    async apiUpdateCoachDailyNotes(params: string): Promise<void> {
      const { environment } = self
      const { api } = environment
      const { data, kind } = await api.updateCoachDailyNote({
        note: params,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.setCoachNote(params)
    },
  }))
  .views((self) => ({
    get pilotChatUnreadMessagesList(): InboxItemType[] {
      const {
        newMessages,
        participantDetails,
        lastMessage,
      } = self?.currentGroupUnreadMessagesMeta

      return Object.keys(lastMessage.toJSON())
        .map((participantID) => {
          return {
            newMessages: newMessages.get(participantID),
            participant: participantDetails.get(participantID),
            latestMessage: lastMessage.get(participantID),
          }
        })
        .sort(sortByTheLastestMessage)
    },
    get groupsNamesMap() {
      return self.affinityGroups.concat(self.oneOneGroups).reduce(
        (acc, nextGroup) => ({
          ...acc,
          [nextGroup.groupID]: nextGroup.groupName,
        }),
        {},
      )
    },

    get coachGreetingMessage() {
      const currentHour = moment().hour()

      let greetingMessage = `Good Evening, Coach`

      if (currentHour >= 0 && currentHour < 12) {
        greetingMessage = `Good Morning, Coach`
      } else if (currentHour >= 12 && currentHour < 17) {
        greetingMessage = `Good Afternoon, Coach`
      }

      const currentDate = moment().format("MMMM Do YYYY")

      return { greetingMessage, currentDate }
    },

    get conversationsWithFlaggedMessagesList(): InboxItemType[] {
      const {
        newMessages,
        participantDetails,
        lastMessage,
      } = self?.currentGroupSavedChatsPagination

      return Object.keys(lastMessage.toJSON())
        .map((participantID) => {
          return {
            newMessages: newMessages.get(participantID) || 0,
            participant: participantDetails.get(participantID),
            latestMessage: lastMessage.get(participantID),
          }
        })
        .sort(sortByTheLastestMessage)
    },
  }))

export interface PilotTrainerStore
  extends Instance<typeof PilotTrainerStoreModel> {}
export interface PilotTrainerStoreSnapshot
  extends SnapshotOut<typeof PilotTrainerStoreModel> {}
