import { useRef } from "react"
import {
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
} from "@material-ui/core"

import { debounce } from "rambdax"
import { useSnackbars } from "../use-snackbar"
import { useStores } from "../../models/root-store"
import { ModalMode } from "./create-tri-modules-modal"
import { FieldTypes } from "../admin-config-form"
import { useFormState } from "../admin-config-form/useFormState"

import { TriCategory } from "../../models/tri-category"

const useTriModulesForm = ({
  isModalOpen,
  mode,
  closeModal,
}: {
  isModalOpen: boolean
  mode: ModalMode
  closeModal: () => void
}) => {
  const { setAndShowSnackbar } = useSnackbars()

  const { adminMslEventStoreModel } = useStores()
  const {
    triContentModules,
    triCategoriesPagination,
    apiAdminModulesUploadContent,
  } = adminMslEventStoreModel
  const {
    newModule,
    resetNewModule,
    apiAdminCreateTriModule,
    apiAdminEditTriModule,
    apiAdminAddCategoryToModule,
    apiAdminDeleteCategoryFromModule,
  } = triContentModules

  const {
    categories,
    uploadFiles,
    setUploadFile,
    setCategories,
    createModuleErrors: errors,
  } = newModule

  const { validateErrors, loading, setLoading, submitted } = useFormState({
    isModalOpen,
    errors,
  })

  const onCancel = () => {
    resetNewModule()
    triCategoriesPagination.resetPagination()
    closeModal()
  }

  const onSubmit = async () => {
    const isValid = validateErrors()
    if (!isValid) {
      return
    }

    try {
      setLoading(true)
      await apiAdminModulesUploadContent()

      if (mode === ModalMode.CREATE) {
        await apiAdminCreateTriModule()
        setAndShowSnackbar({
          text: "Successfully create module !",
          severity: "success",
        })
      } else {
        await apiAdminEditTriModule()
        setAndShowSnackbar({
          text: "Successfully edited module !",
          severity: "success",
        })
      }
      triCategoriesPagination.resetPagination()
      closeModal()
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    } finally {
      setLoading(false)
    }
  }

  const handleCategoriesChange = async (
    event: React.SyntheticEvent<Element, Event>,
    value: TriCategory[],
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<TriCategory>,
  ) => {
    event.preventDefault()
    const areValidCategories = value.every((e) => e?.id)
    if (areValidCategories) {
      setCategories(value.slice())
    }

    const { option } = details
    try {
      if (reason === "selectOption") {
        await apiAdminAddCategoryToModule({
          moduleID: newModule.id,
          categoryID: option.id,
        })
        setAndShowSnackbar({
          text: `Successfully added ${option.title} category !`,
        })
      }
      if (reason === "removeOption") {
        await apiAdminDeleteCategoryFromModule({
          moduleID: newModule.id,
          categoryID: option.id,
        })
        setAndShowSnackbar({
          text: `Successfully removed ${option.title} category !`,
        })
      }
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    }
  }

  const handleSelectFocus = () => {
    if (!triCategoriesPagination.query.trim().length) {
      triCategoriesPagination.resetPagination()

      triCategoriesPagination.apiAdminGetTriCategories()
    } else {
      triCategoriesPagination.apiAdminSearchTriCategories({
        query: triCategoriesPagination.query,
      })
    }
  }

  const debouncedSearch = useRef(
    debounce(async (query: string) => {
      try {
        await triCategoriesPagination.apiAdminSearchTriCategories({ query })
      } catch (error) {
        setAndShowSnackbar({ text: error.message, severity: "error" })
      }
    }, 1000),
  )

  const handleCategorySearch = async (value: string, isEndReached: boolean) => {
    const {
      query,
      setQuery,
      apiAdminGetTriCategories,
      resetTriCategories,
    } = triCategoriesPagination

    try {
      if (isEndReached && !query?.trim().length) {
        apiAdminGetTriCategories()

        return
      }

      if (value?.trim().length) {
        resetTriCategories()
        setQuery(value)

        debouncedSearch.current(value)
      }
    } catch (e) {
      setAndShowSnackbar({
        text: "Retriving What's failed !",
        severity: "error",
      })
    }
  }

  const triModuleFormConfig = [
    {
      fieldName: "title",
      onChangeMethod: "setTitle",
      label: "Title",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter title",
      },
    },
    {
      fieldName: "iconURI",
      onChangeMethod: "setIconURI",
      label: "Icon URI",
      required: true,
      fieldType: FieldTypes.FilePicker,
      componentProps: {
        placeholder: "Enter icon URI or select image locally",
        filePickerProps: {
          label: "Select image",
          fileType: "train_category_image",
          inputId: "iconURI",
          onChange: setUploadFile,
          uploadFiles: uploadFiles.iconURI,
        },
      },
    },
    {
      fieldName: "iconURILarge",
      onChangeMethod: "setIconURILarge",
      label: "Icon URI large",
      required: true,
      fieldType: FieldTypes.FilePicker,
      componentProps: {
        placeholder: "Enter icon URI large or select image locally",
        filePickerProps: {
          label: "Select image",
          fileType: "train_category_image",
          inputId: "iconURILarge",
          onChange: setUploadFile,
          uploadFiles: uploadFiles.iconURILarge,
        },
      },
    },
    {
      fieldName: "categories",
      onChangeMethod: "setCategories",
      label: "Whats",
      fieldType: FieldTypes.Select,
      componentProps: {
        id: "categories",
        multiple: true,
        forbidDuplicates: true,
        options: triCategoriesPagination.categories.slice(),
        value: categories.slice(),
        inputValue: triCategoriesPagination.query,
        loading: triCategoriesPagination.loading,
        onChange: handleCategoriesChange,
        onInputChange: (_, value, reason) => {
          if (reason === "reset") {
            return
          }

          if (!value?.trim().length) {
            triCategoriesPagination.resetPagination()
            triCategoriesPagination.apiAdminGetTriCategories()

            return
          }

          handleCategorySearch(value, false)
        },
        onScrollEnd: () => handleCategorySearch(null, true),
        onFocus: handleSelectFocus,
        renderOption: (props, option: TriCategory) => (
          <li {...props} key={`${option.id}_${props["aria-rowindex"]}`}>
            {option.title}
          </li>
        ),
        getOptionLabel: (option: TriCategory) => option.title,
        Input: {
          placeholder: "Select What's",
        },
        showTags: true,
        tagDisplayNameField: "title",
      },
      showOnlyOnEditMode: true,
    },
  ]

  return {
    newModule,
    loading,
    submitted,
    onCancel,
    onSubmit,
    errors,
    triModuleFormConfig,
  }
}

export { useTriModulesForm }
