import React from "react"
import { Theme } from "@material-ui/core"
import { GridCellProps } from "@mui/x-data-grid"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import moment from "moment"

import { Module } from "../../models/tri-content-modules"
import { CustomTooltip, ShortText } from "../admin-form-components"
import { TriModulesActions } from "./tri-modules-actions"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      width: "100%",
      maxWidth: 100,
    },
    largeImage: {
      width: "100%",
      maxWidth: 200,
    },
  }),
)

interface CellComponentProps extends GridCellProps {
  onEdit?: (event: Module) => void
  moduleID?: string
}
const CellComponent = ({ onEdit, moduleID, ...props }: CellComponentProps) => {
  const classes = useStyles()

  switch (props.field) {
    case "createdAt":
      return (
        <ShortText value={moment(props.value).format("MMM DD, YYYY hh:mm a")} />
      )

    case "iconURI":
      return (
        <>
          {!!props.value ? (
            <CustomTooltip title={props.value}>
              <img src={props.value} alt="icon URI" className={classes.image} />
            </CustomTooltip>
          ) : (
            "N/A"
          )}
        </>
      )

    case "iconURILarge":
      return (
        <>
          {!!props.value ? (
            <CustomTooltip title={props.value}>
              <img
                src={props.value}
                alt="icon URI Large"
                className={classes.largeImage}
              />
            </CustomTooltip>
          ) : (
            "N/A"
          )}
        </>
      )
    case "actions":
      return <TriModulesActions moduleID={moduleID} onEdit={onEdit} />

    default:
      return <ShortText value={props.value} />
  }
}

export { CellComponent }
