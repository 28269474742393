import { types, Instance, SnapshotOut, clone } from "mobx-state-tree"
import moment from "moment"
import numeral from "numeral"
import { prop, take, takeLast, uniqBy } from "ramda"

import { Attendance, AttendanceModel } from "../attendance"
import { ChatType } from "../chat-message"
import {
  ChatMessageLog,
  ChatMessageLogModel,
} from "../chat-message/chat-message-log"
import {
  calculateSRBAIAverage,
  getPBCProgressPerformance,
  getSRBAIProgressPerformance,
} from "../../utils/person-progress"
import { Tri, TriModel } from "../tri"
import * as customTypes from "../types"
import { Biometrics, BiometricsModel } from "../biometrics"
import { PersonCondition, PersonConditionModel } from "../person-condition"
import { Badge, BadgeModel } from "../badge"
import { Score, ScoreModel } from "../score"
import { ProfileModel } from "./profile"
import { Note, NoteModel } from "../note"
import { ScoreType } from "../affinity-group"
import {
  AffinityChatHistory,
  AffinityChatHistoryModel,
} from "../affinity-chat-history"

export enum MemberStatus {
  Registered = "registered",
  Invited = "invited",
}

export enum ActivityStatus {
  Inactive = "inactive",
  Active = "active",
}

const memberStatusTypeLiterals = Object.values(MemberStatus).map((v) =>
  types.literal(v),
)
const activityStatusTypeLiterals = Object.values(ActivityStatus).map((v) =>
  types.literal(v),
)

export const PersonModel = types
  .model("Person")
  .props({
    id: types.identifier,
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    avatarURL: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    countryCode: types.optional(types.string, ""),
    mobilePhone: types.optional(types.string || types.null, ""),
    zipCode: types.optional(types.string, ""),
    motivation: types.optional(types.string, ""),
    profile: types.optional(ProfileModel, () =>
      ProfileModel.create({ id: "-1" }),
    ),
    createdAt: types.optional(customTypes.iso8601, moment().toDate()),
    updatedAt: types.optional(customTypes.iso8601, moment().toDate()),
    groupID: types.maybeNull(types.string),
    personID: types.maybeNull(types.string),
    memberStatus: types.optional(
      types.union(...memberStatusTypeLiterals),
      MemberStatus.Invited,
    ),
    activityStatus: types.optional(
      types.union(...activityStatusTypeLiterals),
      ActivityStatus.Inactive,
    ),
    logs: types.optional(types.array(ChatMessageLogModel), []),
    tris: types.optional(types.array(TriModel), []),
    attendances: types.optional(types.array(AttendanceModel), []),
    biometrics: types.optional(types.array(BiometricsModel), []),
    conditions: types.optional(types.array(PersonConditionModel), []),
    badges: types.optional(types.array(BadgeModel), []),
    pbcScores: types.map(ScoreModel),
    srbaiScores: types.map(ScoreModel),
    totalWeightLossValue: types.maybeNull(types.number),
    totalWeightLossUnit: types.maybeNull(types.string),
    totalExerciseMinutesValue: types.maybeNull(types.number),
    totalExerciseMinutesUnit: types.maybeNull(types.string),
    lastCheckin: types.maybeNull(customTypes.iso8601),
    coachingStartDate: types.maybeNull(customTypes.iso8601),
    coachingEndDate: types.maybeNull(customTypes.iso8601),
    employer: types.maybeNull(types.string),
    notes: types.optional(types.array(NoteModel), []),
    timezone: types.maybeNull(types.string),
    latestNote: types.maybeNull(NoteModel),
    newNote: types.optional(NoteModel, () =>
      NoteModel.create({
        id: "-1",
      }),
    ),
    reasonForBeingHere: types.optional(types.maybeNull(types.string), ""),
    segmentation: types.optional(types.maybeNull(types.string), ""),
    isCoach: types.optional(types.maybeNull(types.boolean), null),
    memberAppEngagementScore: types.optional(
      types.maybeNull(
        types.enumeration<ScoreType>("ScoreType", ["low", "medium", "high"]),
      ),
      null,
    ),
    memberGroupEngagementScore: types.optional(
      types.maybeNull(
        types.enumeration<ScoreType>("ScoreType", ["low", "medium", "high"]),
      ),
      null,
    ),
    chatHistory: types.optional(types.array(AffinityChatHistoryModel), []),
  })
  .actions((self) => ({
    appendLogs(value: Array<ChatMessageLog>) {
      self.logs.replace(uniqBy(prop("id"), [...self.logs, ...value]))
    },
    appendTris(value: Array<Tri>) {
      self.tris.replace(uniqBy(prop("triID"), [...self.tris, ...value]))
    },
    appendAttendances(value: Array<Attendance>) {
      self.attendances.replace(
        uniqBy(prop("sessionID"), [...self.attendances, ...value]),
      )
    },
    setAttendances(value: Array<Attendance>) {
      self.attendances.replace(value)
    },
    setBiometrics(value: Array<Biometrics>) {
      self.biometrics.replace(value)
    },
    setConditions(value: Array<PersonCondition>) {
      self.conditions.replace(value)
    },
    setBadges(value: Array<Badge>) {
      self.badges.replace(value)
    },
    setPbcScores(value: { [key: string]: Score }) {
      self.pbcScores.replace(value)
    },
    setSrbaiScores(value: { [key: string]: Score }) {
      self.srbaiScores.replace(value)
    },
    setTotalWeightLossValue(value: number) {
      self.totalWeightLossValue = value
    },
    setTotalWeightLossUnit(value: string) {
      self.totalWeightLossUnit = value
    },
    setTotalExerciseMinutesValue(value: number) {
      self.totalExerciseMinutesValue = value
    },
    setTotalExerciseMinutesUnit(value: string) {
      self.totalExerciseMinutesUnit = value
    },
    setMotivation(value: string) {
      self.motivation = value
    },
    setSegmentation(value: string) {
      self.segmentation = value
    },
    setEmployer(value: string) {
      self.employer = value
    },
    setReasonForBeingHere(value: string) {
      self.reasonForBeingHere = value
    },
    setNotes(value: Array<Note>) {
      self.notes.replace(value)
    },
    setNewNote(value: Note) {
      self.newNote = clone(value)
    },
    setChatHistory(value: Array<AffinityChatHistory>) {
      self.chatHistory.replace(value)
    },
    resetLogs() {
      self.logs.clear()
    },
    resetTris() {
      self.tris.clear()
    },
    resetNotes() {
      self.notes.clear()
    },
    resetNewNote() {
      self.newNote = NoteModel.create({
        id: "-1",
      })
    },
  }))
  .views((self) => ({
    get initials() {
      const firstLetter = !!self.firstName ? take(1, self.firstName) : ""
      const lastLetter = !!self.lastName ? takeLast(1, self.lastName) : ""
      return [firstLetter, lastLetter].filter(Boolean).join("")
    },
    get firstNameWithLastInitial() {
      const lastLetter = !!self.lastName ? take(1, self.lastName) : ""
      return [self.firstName, lastLetter].filter(Boolean).join(" ")
    },
    get fullName() {
      return [self.firstName, self.lastName].filter(Boolean).join(" ")
    },
    get formattedActivityStatus(): string {
      return self.activityStatus
    },
    get totalWeightLostFormatted() {
      return (
        [self.totalWeightLossValue?.toFixed(1), self.totalWeightLossUnit]
          .filter(Boolean)
          .join(" ") || "0.0 lbs"
      )
    },

    get totalExerciseMinutesFormatted() {
      return [self.totalExerciseMinutesValue?.toFixed(1), "min"]
        .filter(Boolean)
        .join(" ")
    },

    get lastWeightEntry(): ChatMessageLog | null {
      if (this.weightLogs.length) {
        return this.weightLogs[0]
      }
      return null
    },

    get firstWeightEntry(): ChatMessageLog | null {
      if (this.weightLogs.length) {
        return this.weightLogs[this.weightLogs.length - 1]
      }
      return null
    },

    get formattedFirstWeightEntryValue(): string {
      return [
        this.firstWeightEntry?.value?.toFixed(1),
        this.firstWeightEntry?.unit,
      ]
        .filter(Boolean)
        .join(" ")
    },

    get formattedLastWeightEntryValue(): string {
      return [
        this.lastWeightEntry?.value?.toFixed(1),
        this.lastWeightEntry?.unit,
      ]
        .filter(Boolean)
        .join(" ")
    },

    get lastWeightEntryCreatedAt(): string {
      if (this.weightLogs.length) {
        const createdAt = this.lastWeightEntry.createdAt
        return moment(createdAt).format("ll")
      }
      return "N/A"
    },

    get weightLossChangeSinceLastEntryInPercentage(): string | null {
      const lastWeightEntry = this.lastWeightEntry
      const firstWeightEntry = this.firstWeightEntry

      if (lastWeightEntry && firstWeightEntry) {
        const weightDiffPercentage =
          (firstWeightEntry.value - lastWeightEntry.value) /
          lastWeightEntry.value

        return `${numeral(weightDiffPercentage).format("0%")}`
      }
      return "0%"
    },

    get statusForTable() {
      return self.memberStatus === MemberStatus.Registered
        ? this.formattedActivityStatus
        : self.memberStatus
    },

    get hasUnverifiedPhoto() {
      return this.weightLogsWithPhotos.some((log) => {
        return !log.verified
      })
    },

    get logsSortedByMostRecent() {
      return self.logs.slice().sort((logA, logB) => {
        //@ts-expect-error
        return moment.unix(logB.createdAt) - moment.unix(logA.createdAt)
      })
    },

    get trisSortedByMostRecent() {
      return self.tris.slice().sort((triA, triB) => {
        //@ts-expect-error
        return moment.unix(triB.startedAt) - moment.unix(triA.startedAt)
      })
    },

    get attendancesSortedByAsc() {
      return self.attendances.slice().sort((attA, attB) => {
        //@ts-expect-error
        return moment.unix(attA.sessionDate) - moment.unix(attB.sessionDate)
      })
    },

    get weightLogs() {
      return this.logsSortedByMostRecent.filter(
        (l) => l.type === ChatType.Weight,
      )
    },
    get weightLogsWithPhotos() {
      return this.weightLogs.filter((log: ChatMessageLog) => !!log.imageURL)
    },
    get exerciseLogs() {
      return this.logsSortedByMostRecent.filter(
        (l) => l.type === ChatType.Exercise,
      )
    },
    get lastExerciseEntry(): ChatMessageLog | null {
      if (this.exerciseLogs.length) {
        return this.exerciseLogs[0]
      }
      return null
    },
    get currentTri() {
      return self.tris.length ? self.tris[0] : null
    },

    get titleForChat(): string {
      return [this.fullName, "1:1 Chat"].filter(Boolean).join(" ")
    },
    get formattedLastCheckIn() {
      try {
        if (!self.lastCheckin) {
          return null
        }
        const formattedDate = moment(self.lastCheckin)
        //filter weird dates with year 1 or year 0
        if (formattedDate.get("year") < 1800) {
          return null
        }

        return formattedDate.format("ll")
      } catch (error) {
        return null
      }
    },
    get isWeightProgressPositive() {
      return self.totalWeightLossValue < 0
    },
    get isWeightProgressNegative() {
      return self.totalWeightLossValue > 0
    },
    get isActivityProgressPositive() {
      return self.totalExerciseMinutesValue > 0
    },
    get isActivityProgressNegative() {
      return self.totalExerciseMinutesValue < 0
    },
    get lastBiometricUpdate(): Biometrics | null {
      return self.biometrics.length === 0 ? null : self.biometrics[0]
    },
    get formattedConditionsList(): { id: string; label: string }[] {
      if (self.conditions.length === 0) {
        return []
      }
      return self.conditions[0].optionIDs.map(({ id, optionText }) => ({
        id,
        label: optionText,
      }))
    },
    get currentPbcScore() {
      const sortedValues = Object.values(self.pbcScores.toJSON()).sort(
        (a, b) => {
          const first = moment(a.createdAt).unix() * 1000
          const second = moment(b.createdAt).unix() * 1000

          //sorting by the latest date
          return second - first
        },
      )

      const value = sortedValues[0]?.score || 0
      const showProgress = sortedValues.length > 1
      const progress = showProgress
        ? sortedValues[0].score - sortedValues[1].score
        : 0
      const performance = getPBCProgressPerformance(value, showProgress)

      return {
        value,
        showProgress,
        progress,
        performance,
      }
    },
    get currentSrbaiScore() {
      const sortedValues = Object.values(self.srbaiScores.toJSON()).sort(
        (a, b) => {
          const first = moment(a.createdAt).unix() * 1000
          const second = moment(b.createdAt).unix() * 1000

          //sorting by the latest date
          return second - first
        },
      )

      const value = calculateSRBAIAverage(sortedValues[0]?.score || 0)
      const showProgress = sortedValues.length > 1
      const progress = showProgress
        ? calculateSRBAIAverage(sortedValues[0].score) -
          calculateSRBAIAverage(sortedValues[1].score)
        : 0
      const performance = getSRBAIProgressPerformance(value, showProgress)

      return {
        value,
        showProgress,
        progress,
        performance,
      }
    },
    get formattedCoachingStartDate() {
      return self.coachingStartDate
        ? moment(self.coachingStartDate).format("MM-DD-YYYY")
        : ""
    },
    get formattedCoachingEndDate() {
      return self.coachingEndDate
        ? moment(self.coachingEndDate).format("MM-DD-YYYY")
        : ""
    },
    get coachingStatus() {
      if (!Boolean(self.coachingEndDate)) {
        return "Active"
      }
      const expired = moment(self.coachingEndDate).isBefore(moment())
      if (expired) {
        return "Expired"
      }
      const isStartDateInPast = moment(self.coachingStartDate).isBefore(
        moment(),
      )
      const isEndDateInFuture = moment(moment()).isBefore(self.coachingEndDate)

      if (isStartDateInPast && isEndDateInFuture) {
        return "Active"
      } else {
        return "Expired"
      }
    },

    get participantChatHistory() {
      const sessions = self.chatHistory.map((session) => {
        const sessionIsClosed = session.chats.some(
          (chat) => chat.type === "direct_chat_ended",
        )

        if (sessionIsClosed) {
          const closedChat = session.chats.find(
            (chat) => chat.type === "direct_chat_ended",
          )
          const timestamp = closedChat.createdAt
          const formatedTimeStamp = moment(timestamp).format("MMMM Do YYYY")
          return {
            ...session,
            status: "closed",
            closedTimeStamp: formatedTimeStamp,
          }
        }

        return {
          ...session,
          status: session.awaitingResponse ? "open" : "pending",
        }
      })

      return sessions
    },
  }))

export interface Person extends Instance<typeof PersonModel> {}
export interface PersonSnapshot extends SnapshotOut<typeof PersonModel> {}
