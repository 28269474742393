import moment from "moment"

import { MslEvent } from "../msl-event"
import { Tag } from "../tag"

export const sortMslEvents = (eventsList: MslEvent[]): MslEvent[] => {
  return eventsList.sort((a, b) => {
    const first = moment(a.scheduledStartTime).unix() * 1000
    const second = moment(b.scheduledStartTime).unix() * 1000

    //sorting by the latest date
    return second - first
  })
}

export const sortTags = (tagsList: Tag[]): Tag[] => {
  return tagsList.sort((a, b) => a.displayName.localeCompare(b.displayName))
}

export const transformMslEventParams = (event: MslEvent) => {
  const {
    id,
    name,
    description,
    scheduledStartTime,
    scheduledEndTime,
    posterImageURL,
    topicID,
    trainerIDV2,
    recordingAvailable,
    recordingURL,
    audioOnlyImageURL,
    isFeatured,
    audioOnly,
    publishStartTime,
    publishEndTime,
    durationValue,
    script,
    searchKeywords,
    previewStartTime,
    hlsVideoURL,
  } = event

  const hasTopicId = topicID?.length !== 0

  return {
    id,
    name,
    description,
    scheduledStartTime,
    scheduledEndTime,
    posterImageUrl: posterImageURL,
    trainerID: trainerIDV2,
    recordingAvailable,
    recordingURL,
    audioOnlyImageURL,
    duration: durationValue,
    isFeatured,
    audioOnly,
    publishStartTime,
    publishEndTime,
    script,
    searchKeywords,
    topicID: hasTopicId ? topicID : null,
    previewStartTime,
    hlsVideoURL: hlsVideoURL.length > 0 ? hlsVideoURL : null,
  }
}
