import { types, Instance, SnapshotOut } from "mobx-state-tree"
import moment from "moment"

import { EnrolledProgramsModel } from "../admin-user-search/enrolled-programs"
import * as customTypes from "../types"

export const ProfileModel = types
  .model("Profile")
  .props({
    id: types.identifier,
    smsAllowed: types.optional(types.boolean, false),
    timezone: types.maybeNull(types.string),
    dailyQuote: types.maybeNull(types.string),
    pinnedQuote: types.maybeNull(types.string),
    savedQuotes: types.maybeNull(types.array(types.string)),
    savedIdeas: types.maybeNull(types.array(types.string)),
    entitlements: types.maybeNull(types.array(types.string)),
    enrolledPrograms: types.optional(
      types.maybeNull(types.array(EnrolledProgramsModel)),
      null,
    ),
    associatedPartners: types.maybeNull(types.array(types.string)),
    recentBadges: types.maybeNull(types.array(types.string)),
    intentionNotifyTime: types.maybeNull(types.string),
    intentionNotifyEnabled: types.optional(types.boolean, false),
    gratitudeNotifyTime: types.optional(customTypes.iso8601, moment().toDate()),
    gratitudeNotifyEnabled: types.optional(types.boolean, false),
    triggerNotifyTime: types.optional(customTypes.iso8601, moment().toDate()),
    triggerNotifyEnabled: types.optional(types.boolean, false),
    nudgeChannelPreference: types.maybeNull(types.string),
    createdAt: types.optional(customTypes.iso8601, moment().toDate()),
    updatedAt: types.optional(customTypes.iso8601, moment().toDate()),
    uniqueID: types.maybeNull(types.string),
    fcmDeviceToken: types.maybeNull(types.string),
    userPostAllowed: types.optional(types.boolean, false),
    avatarImageURL: types.maybeNull(types.string),
    team: types.maybeNull(types.string),
    egiftCardID: types.maybeNull(types.string),
    coachID: types.optional(types.maybeNull(types.string), null),
  })
  .actions((self) => ({
    setAvatarImageUrl(value: string) {
      self.avatarImageURL = value
    },
  }))

export interface Profile extends Instance<typeof ProfileModel> {}
export interface ProfileSnapshot extends SnapshotOut<typeof ProfileModel> {}
