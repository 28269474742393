import { Instance, SnapshotOut, types, isAlive, clone } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../extensions"
import { uniqBy, prop } from "ramda"
import moment from "moment"

import { replaceObjectById } from "../../utils/replace-object-in-array"
import { removeObjectById } from "../../utils/remove-object-from-array"
import { Module, ModuleModel } from "./module"

export const TriContentModulesModel = types
  .model("TriContentModules")
  .extend(withEnvironment)
  .extend(withRootStore)
  .props({
    modules: types.optional(types.array(ModuleModel), []),
    newModule: types.optional(ModuleModel, () =>
      ModuleModel.create({
        id: "-1",
      }),
    ),
  })
  .actions((self) => ({
    setModules(value: Module[]) {
      self.modules.replace(uniqBy(prop("id"), [...self.modules, ...value]))
    },

    appendModule(value: Module) {
      self.modules.replace([value, ...self.modules])
    },

    updateModule(value: Module) {
      const updatedModulesList = replaceObjectById(self.modules, value)

      self.modules.replace(updatedModulesList as Module[])
    },
    deleteModule(value: string) {
      const updatedModulesList = removeObjectById(self.modules, value)

      self.modules.replace(updatedModulesList as Module[])
    },
    setNewModule(value: Module) {
      self.newModule = clone(value)
    },
    resetModules() {
      if (isAlive(self.modules)) {
        self.modules.clear()
      }
    },
    resetNewModule() {
      self.newModule = ModuleModel.create({
        id: "-1",
      })
    },
  }))
  .actions((self) => ({
    async apiAdminGetTriModules() {
      const { api } = self.environment
      const { kind, data } = await api.adminGetTriModules()

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.setModules(data)
    },
  }))
  .actions((self) => ({
    async apiAdminCreateTriModule(): Promise<void> {
      const { api } = self.environment
      const { title, iconURI, iconURILarge } = self.newModule
      const { data, kind } = await api.adminCreateTriModule({
        title,
        iconURI,
        iconURILarge,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.appendModule(data)
      self.resetNewModule()
    },
    async apiAdminEditTriModule(): Promise<void> {
      const { api } = self.environment
      const { id, title, iconURI, iconURILarge } = self.newModule
      const { data, kind } = await api.adminEditTriModule({
        id,
        title,
        iconURI,
        iconURILarge,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      self.updateModule(data)
      self.resetNewModule()
    },
    async apiAdminDeleteTriModule(moduleID: string): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.adminDeleteTriModule({
        moduleID,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      self.deleteModule(moduleID)
    },

    async apiAdminAddCategoryToModule({
      moduleID,
      categoryID,
    }: {
      moduleID: string
      categoryID: string
    }) {
      const { api } = self.environment

      const { kind, data } = await api.adminAddCategoryToModule({
        moduleID,
        categoryID,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetTriModules()
    },

    async apiAdminDeleteCategoryFromModule({
      moduleID,
      categoryID,
    }: {
      moduleID: string
      categoryID: string
    }): Promise<void> {
      const { api } = self.environment

      const { data, kind } = await api.adminDeleteCategoryFromModule({
        moduleID,
        categoryID,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetTriModules()
    },
  }))
  .views((self) => ({
    get formattedModules() {
      return self.modules.slice().sort((a, b) => {
        const first = moment(a.createdAt).unix() * 1000
        const second = moment(b.createdAt).unix() * 1000

        //sorting by the latest date
        return second - first
      })
    },
  }))

export interface TriContentModules
  extends Instance<typeof TriContentModulesModel> {}
export interface TriContentModulesSnapshot
  extends SnapshotOut<typeof TriContentModulesModel> {}
