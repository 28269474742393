import { useEffect, useState, useCallback } from "react"
import { observer } from "mobx-react-lite"
import { useGridApiRef } from "@mui/x-data-grid"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import AddIcon from "@mui/icons-material/Add"

import { Theme, Stack, Typography, Button } from "@material-ui/core"
import { GridCellProps } from "@mui/x-data-grid"
import { useStores } from "../../models/root-store"
import { typography } from "../../services/theme/typography"

import { CustomGridTable, TableCell } from "../custom-grid-table"
import { CellComponent } from "./cell-component"
import { LIMIT } from "../../models/tri-ideas-pagination"
import { CreateTriIdeaModal, ModalMode } from "./create-tri-idea-modal"
import { TriIdea } from "../../models/tri-idea"
import { useTable } from "./useTable"
import { columns } from "./table-config"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: "1 1 1px",
      flexDirection: "column",
      overflow: "auto",
      padding: theme.spacing(4, 12, 7, 6),
    },
    tableLabel: {
      fontSize: theme.spacing(2.5),
      lineHeight: theme.spacing(3),
      color: theme.palette.text.secondary,
      ...typography.circularXXMedium,
    },
    label: {
      fontWeight: "bold",
      fontSize: theme.spacing(2.25),
    },

    tableCell: {
      width: "17% !important",
      maxWidth: "17% !important",
      display: "flex",
      borderBottom: "unset",
      wordBreak: "break-word",
    },
    tableColumnHeader: {
      width: "17% !important",
      minWidth: "unset !important",
      maxWidth: "unset !important",
      height: "unset !important",
    },
  }),
)

const AdminTriIdeasList = observer(() => {
  const classes = useStyles()
  const apiRef = useGridApiRef()
  const [firstLoading, toggleFirstLoading] = useState(true)
  const [isCreateTriIdeaModalOpen, toggleCreateTriIdeaModalOpen] = useState(
    false,
  )
  const [triIdeaModalMode, setTriIdeaModalMode] = useState<ModalMode>(
    ModalMode.CREATE,
  )

  const { adminMslEventStoreModel } = useStores()
  const {
    triIdeasPagination,
    apiAdminGetTags,
    setNewTriIdea,
    apiAdminGetTriIdeaTypes,
    resetNewTriIdea,
  } = adminMslEventStoreModel

  const { apiAdminGetTriIdeas } = triIdeasPagination

  const {
    ideas,
    totalCount,
    loading,
    limit: currentPageSize,
    page: currentPage,
    setPage,
    setLimit,
    setLoading,
    resetPagination,
  } = triIdeasPagination

  useEffect(() => {
    const initialFetch = async () => {
      try {
        await apiAdminGetTriIdeaTypes()
        await apiAdminGetTags()
      } catch (e) {}
      toggleFirstLoading(false)
      setLoading(false)
    }
    resetPagination()
    fetchIdeas()
    initialFetch()

    return () => {
      triIdeasPagination.resetPagination()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchIdeas = async () => {
    try {
      await apiAdminGetTriIdeas()
      toggleFirstLoading(false)
      setLoading(false)
    } catch (e) {
      toggleFirstLoading(false)
      setLoading(false)
    }
  }

  const openCreateTriIdeaModal = () => {
    resetNewTriIdea()
    setTriIdeaModalMode(ModalMode.CREATE)
    toggleCreateTriIdeaModalOpen(true)
  }

  const openEditTriIdeaModal = useCallback((event: TriIdea) => {
    setNewTriIdea(event)
    setTriIdeaModalMode(ModalMode.EDIT)
    toggleCreateTriIdeaModalOpen(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeCreateTriIdeaModal = () => {
    toggleCreateTriIdeaModalOpen(false)
  }

  const handlePaginationChange = async ({ page, pageSize }) => {
    if (currentPageSize !== pageSize) {
      setLoading(true)
      resetPagination()
      setLimit(pageSize)
      await apiAdminGetTriIdeas()
      return
    }

    if (currentPage < page + 1) {
      setLoading(true)
      setPage(currentPage + 1)
      await apiAdminGetTriIdeas()
    }
  }

  const restorePaginationState = () => {
    const state = apiRef.current.exportState()
    const restoredState = {
      ...state,
      pagination: {
        ...state.pagination,
        paginationModel: {
          ...state.pagination?.paginationModel,
          page: 0,
          pageSize: currentPageSize,
        },
      },
    }
    apiRef.current.restoreState(restoredState)
  }

  const MemoTableCell = useCallback(
    (props: GridCellProps) => (
      <TableCell {...props} tableCellClass={classes.tableCell}>
        <CellComponent
          {...props}
          openEditTriIdeaModal={openEditTriIdeaModal}
          triIdeaID={props.rowId as string}
        />
      </TableCell>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const { handleSortModelChange, onFilterChange } = useTable({
    fetchIdeas,
    restorePaginationState,
  })

  return (
    <div className={classes.root}>
      <Stack
        direction="row"
        flex={1}
        justifyContent="space-between"
        alignItems="center">
        <Typography className={classes.tableLabel}>How Tos</Typography>

        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={openCreateTriIdeaModal}>
          Add How To
        </Button>
      </Stack>

      <CustomGridTable
        apiRef={apiRef}
        columns={columns}
        aria-label="tri-howtos-table"
        cell={MemoTableCell}
        rows={loading ? [] : ideas.slice()}
        onPaginationModelChange={handlePaginationChange}
        rowCount={totalCount}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: LIMIT,
            },
          },
        }}
        pageSizeOptions={[25, 50, 100]}
        emptyStateLabel="When you create some how tos, they will appear here"
        loading={loading || firstLoading}
        classes={{
          columnHeader: classes.tableColumnHeader,
        }}
        sortingMode="server"
        filterMode="server"
        onSortModelChange={handleSortModelChange}
        onFilterModelChange={onFilterChange}
      />

      <CreateTriIdeaModal
        open={isCreateTriIdeaModalOpen}
        closeModal={closeCreateTriIdeaModal}
        mode={triIdeaModalMode}
      />
    </div>
  )
})

export { AdminTriIdeasList }
