import { types, Instance, SnapshotOut } from "mobx-state-tree"
import { AffinityChatModel } from "./affinity-chat"

export const AffinityChatHistoryModel = types
  .model("AffinityChatHistory")
  .props({
    awaitingResponse: types.maybeNull(types.boolean),
    chats: types.optional(types.array(AffinityChatModel), []),
  })

export interface AffinityChatHistory
  extends Instance<typeof AffinityChatHistoryModel> {}
export interface AffinityChatHistorySnapshot
  extends SnapshotOut<typeof AffinityChatHistoryModel> {}
