import { Instance, SnapshotOut, types, isAlive, clone } from "mobx-state-tree"
import { uniqBy, prop } from "ramda"

import { withEnvironment, withRootStore } from "../extensions"
import { AffinitySendMessageToSessionParams } from "../../services/api/api.types"
import { ChatMessageModel, ChatMessage } from "../chat-message/chat-message"
import { ChatType } from "../chat-message"
import { AffinitySession, AffinitySessionModel } from "./session"

export const LIMIT = 20

export const AffinityChatSessionsPaginationModel = types
  .model("AffinityGroupFeedPagination")
  .extend(withEnvironment)
  .extend(withRootStore)
  .props({
    next: types.optional(types.maybeNull(types.number), null),
    limit: types.optional(types.number, LIMIT),
    sessions: types.optional(types.array(AffinitySessionModel), []),
    currentSession: types.optional(AffinitySessionModel, () =>
      AffinitySessionModel.create({
        id: "-1",
      }),
    ),
    currentSessionMessages: types.optional(types.array(ChatMessageModel), []),
    coachHasOpenMessages: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setLimit(value: number) {
      self.limit = value
    },
    setNext(value: number) {
      self.next = value
    },
    setCoachHasOpenMessages(value: boolean) {
      self.coachHasOpenMessages = value
    },
    setSessionsList(value: AffinitySession[]) {
      self.sessions.replace(value)
    },
    appendSessions(value: AffinitySession[]) {
      self.sessions.replace(uniqBy(prop("id"), [...self.sessions, ...value]))
    },
    removeClosedSession(sessionId: string) {
      self.sessions.replace(
        [...self.sessions].filter((session) => session.id !== sessionId),
      )
    },
    setCurrentSession(value: AffinitySession) {
      self.currentSession = clone(value)
    },
    setCurrentSessionFromMessage(value: ChatMessage, participantID: string) {
      self.currentSession = clone(
        AffinitySessionModel.create({
          id: value.sessionID,
          participantID,
          messageText: "",
          authorFirstName: "",
          authorEmail: "",
          avatarURL: "",
        }),
      )
    },

    setCurrentSessionMessages(value: Array<ChatMessage>) {
      self.currentSessionMessages.replace(value)
    },
    appendCurrentSessionMessage(value: ChatMessage) {
      self.currentSessionMessages.replace([
        ...self.currentSessionMessages,
        value,
      ])
    },
    unshiftCurrentSessionMessage(value: ChatMessage) {
      self.currentSessionMessages.replace([
        value,
        ...self.currentSessionMessages,
      ])
    },
    removeCurrentSessionMessage(messageId: string) {
      self.currentSessionMessages.replace(
        [...self.currentSessionMessages].filter(
          (message) => message.id !== messageId,
        ),
      )
    },

    resetCurrentSession() {
      self.currentSession = AffinitySessionModel.create({
        id: "-1",
      })
    },
    resetCurrentSessionMessages() {
      if (isAlive(self.currentSessionMessages)) {
        self.currentSessionMessages.clear()
      }
    },
    resetSessions() {
      if (isAlive(self.sessions)) {
        self.sessions.clear()
      }
    },
  }))
  .actions((self) => ({
    resetPagination() {
      self.limit = LIMIT
      self.next = null
    },
  }))
  .actions((self) => ({
    async apiCheckForNewAffinitySessions({ groupID }: { groupID: string }) {
      const { api } = self.environment
      const { kind, data } = await api.getAffinitySessionChats({
        groupID,
        limit: self.limit,
        filter_by: "open",
        next: 0,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const hasOpenMessages = data.data.length > 0

      self.setCoachHasOpenMessages(hasOpenMessages)
    },

    async apiGettAffinityChatSessions({
      groupID,
      chatType,
      isPagination,
      limit,
    }: {
      groupID: string
      chatType: string
      isPagination?: boolean
      limit?: number
    }) {
      const { api } = self.environment
      const {
        next,
        setNext,
        appendSessions,
        setSessionsList,
        resetPagination,
        setCoachHasOpenMessages,
      } = self

      const { kind, data } = await api.getAffinitySessionChats({
        groupID,
        limit: limit || self.limit,
        filter_by: chatType,
        next: isPagination ? next : 0,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      if (isPagination) {
        appendSessions(data.data)
        setNext(data.paginationMetadata.next)
      } else {
        resetPagination()
        setSessionsList(data.data)

        if (chatType === "open") {
          const coachHasOpenMessages = data.data.length > 0

          setCoachHasOpenMessages(coachHasOpenMessages)
        }
      }
    },

    async apiGetAffinityCurrentSessionMessages({
      groupID,
      sessionID,
    }: {
      groupID: string
      sessionID: string
    }) {
      const { api } = self.environment

      const { kind, data } = await api.getAffinityCurrentSessionMessages({
        groupID,
        sessionID,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.setCurrentSessionMessages(data.data?.reverse?.())
    },
    async apiAffinityGetParticipantSessions({
      groupID,
      participantID,
    }: {
      groupID: string
      participantID: string
    }) {
      const { api } = self.environment

      const { kind, data } = await api.affinityGetParticipantSessions({
        groupID,
        participantID,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const currentsSession = data.data.filter(
        ({ chats }) =>
          !chats.some((message) => message.type === ChatType.Direct_Chat_Ended),
      )
      const currentsSessionMessages = currentsSession[0]?.chats || []
      self.setCurrentSessionMessages(currentsSessionMessages.reverse())

      const lastMessage = currentsSessionMessages[0]
      if (!lastMessage) return
      self.setCurrentSessionFromMessage(lastMessage, participantID)
    },
    async apiSendMessageToSession(
      params: AffinitySendMessageToSessionParams,
    ): Promise<void> {
      const { environment } = self
      const { api } = environment
      const { data, kind } = await api.affinitySendMessageToSession(params)

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.unshiftCurrentSessionMessage(data)
      if (!self.currentSession?.isValid && data) {
        self.setCurrentSessionFromMessage(data, params.personID)
      }
    },
  }))
  .views((self) => ({
    get isCurrentSessionClosed() {
      return self.currentSessionMessages.some(
        (message) => message.type === ChatType.Direct_Chat_Ended,
      )
    },
  }))

export interface AffinityChatSessionsPagination
  extends Instance<typeof AffinityChatSessionsPaginationModel> {}
export interface AffinityChatSessionsPaginationSnapshot
  extends SnapshotOut<typeof AffinityChatSessionsPaginationModel> {}
