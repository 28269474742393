import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { useParams, useLocation } from "react-router-dom"

import { observer } from "mobx-react-lite"
import { Box, Stack, Typography, IconButton, Theme } from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"

import { ReactComponent as UserProfile } from "../../assets/images/user-profile.svg"
import { ReactComponent as CloseChat } from "../../assets/images/close_chat.svg"
import { ReactComponent as CloseIcon } from "../../assets/images/close.svg"

import { RouteParams } from "../../AppRoutes"
import { useStores } from "../../models/root-store"
import { typography } from "../../services/theme/typography"
import { CloseChatModal } from "./close-chat-modal"
import { PersonModel } from "../../models/person"
interface ChatHeaderProps {
  isSlider: boolean
  showViewProfileButton: boolean
  handleModalClose?: () => void
  handleCloseChat?: () => void
}

interface AffinityChatRouteParams extends RouteParams {
  groupId: string
  coachId: string
}

interface LocationState {
  backgroundScreen?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBarContainer: {
      background: "#F5F6F7",
      padding: theme.spacing(3, 2),
      gap: theme.spacing(1),
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexWrap: "wrap",
      borderRadius: theme.spacing(0.5),
    },
    avatar: {
      width: theme.spacing(9),
      height: theme.spacing(9),
      borderRadius: "50%",
    },
    personName: {
      wordBreak: "break-all",
      fontSize: theme.spacing(3),
      lineHeight: theme.spacing(4),
      color: theme.palette.text.secondary,
      ...typography.circularXXMedium,
    },
    personEmail: {
      wordBreak: "break-all",
      fontSize: theme.spacing(2),
      color: "#677278",
      lineHeight: theme.spacing(2.5),
      ...typography.circularXXMedium,
    },
    userProfileButton: {
      background: theme.palette.common.white,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(1),
    },
    closeChatButton: {
      background: theme.palette.common.white,
      borderRadius: theme.spacing(1.5, 2),
      padding: theme.spacing(1),
      border: "2px solid #EE5736",
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
      fontSize: theme.spacing(2),
      color: "#EE5736",
      ...typography.circularXXMedium,
    },
    sliderHeaderContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      padding: theme.spacing(4, 0, 3, 0),
    },
    sliderHeaderLabel: {
      fontSize: theme.spacing(3.4),
      color: "#2D353B",
      fontFamily: typography.circularXXBold.fontFamily,
    },
    sliderHeaderButtonContainer: {
      height: theme.spacing(4.7),
      width: theme.spacing(4.7),
      borderRadius: theme.spacing(2.35),
      backgroundColor: "#F0F3F7",
    },
  }),
)

const ChatHeader = observer(
  ({
    isSlider,
    handleModalClose,
    handleCloseChat,
    showViewProfileButton,
  }: ChatHeaderProps) => {
    const classes = useStyles()
    const [isOpenCloseChatModal, setOpenCloseChatModal] = useState(false)

    const { personStore, pilotTrainerStore, loginStore } = useStores()
    const {
      currentPerson,
      apiGetParticipantDetails,
      setCurrentPerson,
    } = personStore
    const { affinityChatSessionsPagination } = pilotTrainerStore
    const { person } = loginStore

    const { avatarURL, email, firstName, personID, groupID } = currentPerson
    const {
      isCurrentSessionClosed,
      currentSession,
    } = affinityChatSessionsPagination

    const params = useParams<AffinityChatRouteParams>()
    const location = useLocation<LocationState>()
    const isParticipantDetailsBackground = location.state?.backgroundScreen

    useEffect(() => {
      if (
        (!params?.personId && !currentSession?.participantID) ||
        isParticipantDetailsBackground
      )
        return

      const getParticipant = async () => {
        const participantId = params.personId ?? currentSession.participantID
        await apiGetParticipantDetails(participantId)
      }

      getParticipant()

      return () => {
        setCurrentPerson(PersonModel.create({ id: "-1" }))
      }
    }, [
      apiGetParticipantDetails,
      currentSession.participantID,
      params.personId,
      params.roomId,
      setCurrentPerson,
      isParticipantDetailsBackground,
    ])
    const handleCloseChatModalToggle = () => {
      setOpenCloseChatModal((prevStatus) => !prevStatus)
    }

    const closeChat = () => {
      handleCloseChat()
      handleCloseChatModalToggle()
    }

    return (
      <>
        {isSlider && (
          <Stack className={classes.sliderHeaderContainer}>
            <Typography className={classes.sliderHeaderLabel}>
              Member Chat
            </Typography>

            <IconButton
              className={classes.sliderHeaderButtonContainer}
              onClick={handleModalClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
        )}

        <Box className={classes.appBarContainer}>
          <Stack direction="row" alignItems="center" spacing={2}>
            {avatarURL && (
              <img src={avatarURL} alt="Avatar" className={classes.avatar} />
            )}

            <Stack spacing={0.25}>
              <Typography className={classes.personName}>
                {firstName}
              </Typography>

              <Typography className={classes.personEmail}>{email}</Typography>
            </Stack>
          </Stack>

          <Stack direction="row" alignItems="center" spacing={3} pl={1}>
            {showViewProfileButton && (
              <Link
                to={`/pilot-trainer/${person.id}/affinity/${groupID}/affinity-members/${personID}`}>
                <IconButton className={classes.userProfileButton}>
                  <UserProfile />
                </IconButton>
              </Link>
            )}

            {currentSession.isValid && !isCurrentSessionClosed && (
              <IconButton
                className={classes.closeChatButton}
                onClick={handleCloseChatModalToggle}>
                <CloseChat />
                Close Chat
              </IconButton>
            )}
          </Stack>
        </Box>

        <CloseChatModal
          isOpen={isOpenCloseChatModal}
          cancel={handleCloseChatModalToggle}
          closeChat={closeChat}
        />
      </>
    )
  },
)

export { ChatHeader }
