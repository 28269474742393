import { useRef, useMemo } from "react"
import {
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
} from "@material-ui/core"
import { debounce } from "rambdax"
import { useSnackbars } from "../use-snackbar"
import { useStores } from "../../models/root-store"
import { ModalMode } from "./create-tri-behavior-modal"
import { FieldTypes } from "../admin-config-form"
import { useFormState } from "../admin-config-form/useFormState"

import { Tag } from "../../models/tag"
import { TriIdea } from "../../models/tri-idea"

const useTriBehaviorForm = ({
  isModalOpen,
  mode,
  closeModal,
}: {
  isModalOpen: boolean
  mode: ModalMode
  closeModal: () => void
}) => {
  const { setAndShowSnackbar } = useSnackbars()

  const { adminMslEventStoreModel } = useStores()

  const {
    tags: tagsOptions,
    triIdeasPagination,
    triBehaviorsPagination,
  } = adminMslEventStoreModel

  const {
    newTriBehavior,
    apiAdminCreateTriBehavior,
    apiAdminEditTriBehavior,
    apiAdminAddTagToTriBehavior,
    apiAdminDeleteTriBehaviorTag,
    apiAdminAddIdeaToTriBehavior,
    apiAdminDeleteTriBehaviorIdea,
    resetNewTriBehavior,
  } = triBehaviorsPagination

  const {
    tags,
    ideas,
    setTags,
    setIdeas,
    createTriBehaviorErrors: errors,
  } = newTriBehavior

  const { validateErrors, loading, setLoading, submitted } = useFormState({
    isModalOpen,
    errors,
  })

  const handleTagsChange = async (
    event: React.SyntheticEvent<Element, Event>,
    value: Tag[],
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<Tag>,
  ) => {
    event.preventDefault()
    const areValidTags = value.every((e) => e?.id)
    if (areValidTags) {
      setTags(value.slice())
    }

    const { option } = details
    try {
      if (reason === "selectOption") {
        await apiAdminAddTagToTriBehavior(newTriBehavior.id, option.id)
        setAndShowSnackbar({
          text: `Successfully added ${option.displayName} tag !`,
        })
      }
      if (reason === "removeOption") {
        await apiAdminDeleteTriBehaviorTag(newTriBehavior.id, option.id)
        setAndShowSnackbar({
          text: `Successfully removed ${option.displayName} tag !`,
        })
      }
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    }
  }

  const handleIdeasChange = async (
    event: React.SyntheticEvent<Element, Event>,
    value: TriIdea[],
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<TriIdea>,
  ) => {
    event.preventDefault()
    const areValidIdeas = value.every((e) => e?.id)
    if (areValidIdeas) {
      setIdeas(value.slice())
    }

    const { option } = details
    try {
      if (reason === "selectOption") {
        await apiAdminAddIdeaToTriBehavior(newTriBehavior.id, option.id)
        setAndShowSnackbar({
          text: `Successfully added ${option.title} idea !`,
        })
      }
      if (reason === "removeOption") {
        await apiAdminDeleteTriBehaviorIdea(newTriBehavior.id, option.id)
        setAndShowSnackbar({
          text: `Successfully removed ${option.title} idea !`,
        })
      }
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    }
  }

  const debouncedSearch = useRef(
    debounce(async (query: string, searchAPI) => {
      try {
        await searchAPI()
      } catch (error) {
        setAndShowSnackbar({ text: error.message, severity: "error" })
      }
    }, 1000),
  )

  const onCancel = () => {
    resetNewTriBehavior()
    triIdeasPagination.resetPagination()
    closeModal()
  }

  const onSubmit = async () => {
    const isValid = validateErrors()
    if (!isValid) {
      return
    }

    try {
      setLoading(true)

      if (mode === ModalMode.CREATE) {
        await apiAdminCreateTriBehavior()
        setAndShowSnackbar({
          text: "Successfully created how !",
          severity: "success",
        })
      } else {
        await apiAdminEditTriBehavior()
        setAndShowSnackbar({
          text: "Successfully edited how !",
          severity: "success",
        })
      }

      closeModal()
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    } finally {
      setLoading(false)
    }
  }

  const handleIdeasSearch = async (_, value, isEndReached) => {
    const {
      next,
      query,
      setQuery,
      resetPagination,
      apiAdminGetTriIdeasSearch,
    } = triIdeasPagination

    if (isEndReached) {
      if (query.trim().length) {
        await apiAdminGetTriIdeasSearch()

        return
      }

      if (next) {
        await triIdeasPagination.apiAdminGetTriIdeas()
      }
    } else {
      if (value?.trim().length) {
        resetPagination()
        setQuery(value)
        const fetchNewIdeas = () => {
          apiAdminGetTriIdeasSearch()
        }
        debouncedSearch.current(value, fetchNewIdeas)
      }
    }
  }

  const handleIdeasFilters = (filterType, value) => {
    const {
      setFilterBy,
      setSortBy,
      setLimit,
      setNext,
      resetTriIdeas,
    } = triIdeasPagination
    resetTriIdeas()
    setNext(0)

    switch (filterType) {
      case "filterBy":
        setFilterBy(value)
        break

      case "sortBy":
        setSortBy(value)
        break

      case "limit":
        setLimit(value)
        break

      default:
        setLimit(value)
        break
    }
  }

  const handleOnBlurSelect = () => {
    triIdeasPagination.resetTriIdeas()
  }

  const handleSelectFocus = () => {
    if (!triIdeasPagination.query.trim().length) {
      triIdeasPagination.resetPagination()

      triIdeasPagination.apiAdminGetTriIdeas()
    } else {
      triIdeasPagination.apiAdminGetTriIdeasSearch()
    }
  }

  const selectOptions = triIdeasPagination.ideas.slice()
  const selectValues = ideas.slice()

  const ideasFieldConfig = useMemo(() => {
    return {
      fieldName: "ideas",
      onChangeMethod: "setIdeas",
      label: "How Tos",
      fieldType: FieldTypes.Select,
      componentProps: {
        multiple: true,
        forbidDuplicates: true,
        disableClearable: true,
        options: selectOptions,
        value: selectValues,
        onChange: handleIdeasChange,
        loading: triIdeasPagination.loading,
        open: selectOptions.length > 0,
        inputValue: triIdeasPagination.query,
        onInputChange: (_, value, reason) => {
          if (reason === "reset") {
            return
          }
          if (!value?.length) {
            triIdeasPagination.resetPagination()
            triIdeasPagination.apiAdminGetTriIdeas()

            return
          }

          handleIdeasSearch(_, value, false)
        },
        onScrollEnd: () => handleIdeasSearch(null, null, true),
        onFocus: handleSelectFocus,
        onBlur: handleOnBlurSelect,
        filterOptions: (options: TriIdea[]) => {
          return options
        },
        renderOption: (props, option: TriIdea) => (
          <li {...props} key={option.id}>
            {option.title}
          </li>
        ),
        getOptionLabel: (option: TriIdea) => option.title,
        filters: [
          {
            label: "Filter by",
            key: "filterBy",
            value: triIdeasPagination.filterBy,
            options: [
              { label: "Active", value: "active" },
              { label: "Recipe", value: "recipe" },
              { label: "Hack", value: "hack" },
              { label: "Iteration", value: "iteration" },
              { label: "Setup", value: "setup" },
              { label: "Product", value: "product" },
              { label: "Video", value: "video" },
              { label: "Insight", value: "insight" },
            ],
          },
          {
            label: "Sort by",
            key: "sortBy",
            value: triIdeasPagination.sortBy,
            options: [
              { label: "Title", value: "title" },
              { label: "Created At", value: "createdAt" },
            ],
          },
          {
            label: "Limit",
            key: "limit",
            value: triIdeasPagination.limit,
            options: [
              { label: "25", value: 25 },
              { label: "50", value: 50 },
              { label: "100", value: 100 },
            ],
          },
        ],
        onFilterChange: (filterName, filterValue) => {
          handleIdeasFilters(filterName, filterValue)
        },
        Input: {
          placeholder: "Select How-Tos",
        },
        showTags: true,
        tagDisplayNameField: "title",
      },
      showOnlyOnEditMode: true,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectValues, selectOptions, triIdeasPagination.loading])

  const triBehaviorFormConfig = [
    {
      fieldName: "title",
      onChangeMethod: "setTitle",
      label: "Title",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter title",
      },
    },
    {
      fieldName: "titleBareInfinitive",
      onChangeMethod: "setTitleBareInfinitive",
      label: "Title Bare Infinitive",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter title bare infinitive",
      },
    },
    {
      fieldName: "titleBareInfinitive2ndPerson",
      onChangeMethod: "setTitleBareInfinitive2ndPerson",
      label: "Title Bare Infinitive 2nd Person",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter title bare infinitive 2nd person",
      },
    },
    {
      fieldName: "titlePresentContinuous",
      onChangeMethod: "setTitlePresentContinuous",
      label: "Title Present Continuous",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter title present continuous",
      },
    },
    {
      fieldName: "titlePresentContinuous2ndPerson",
      onChangeMethod: "setTitlePresentContinuous2ndPerson",
      label: "Title Present Continuous 2nd Person",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter title present continuous 2nd person",
      },
    },
    {
      fieldName: "summary",
      onChangeMethod: "setSummary",
      label: "Summary",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter title summary",
        multiline: true,
        minRows: 1,
        maxRows: 6,
      },
    },
    {
      fieldName: "iconURI",
      onChangeMethod: "setIconURI",
      label: "Icon URI",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter icon URI",
      },
    },

    {
      fieldName: "citation",
      onChangeMethod: "setCitation",
      label: "Citation",
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter citation",
        multiline: true,
        minRows: 1,
        maxRows: 6,
      },
    },
    {
      fieldName: "tags",
      onChangeMethod: "setTags",
      label: "Tags",
      fieldType: FieldTypes.Select,
      componentProps: {
        multiple: true,
        forbidDuplicates: true,
        disableClearable: true,
        options: tagsOptions.slice(),
        value: tags.slice(),
        onChange: handleTagsChange,
        renderOption: (props, option: Tag) => (
          <li {...props} key={option.id}>
            {option.displayName}
          </li>
        ),
        Input: {
          placeholder: "Select Tags",
        },
        showTags: true,
        tagDisplayNameField: "displayName",
      },
      showOnlyOnEditMode: true,
    },

    ideasFieldConfig,
  ]

  return {
    newTriBehavior,
    loading,
    submitted,
    onCancel,
    onSubmit,
    values: newTriBehavior,
    errors,
    triBehaviorFormConfig,
  }
}

export { useTriBehaviorForm }
