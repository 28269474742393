import React, { memo } from "react"

import { Typography, Card, CardMedia, CardContent } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"

import { typography } from "../../services/theme/typography"
import { ShortText } from "../admin-form-components"

import { MslEvent } from "../../models/msl-event"

interface VideoCardProps {
  video: MslEvent
  selected: boolean
  onClick: () => void
}

const useStyles = makeStyles((theme) =>
  createStyles({
    videoCardContainer: {
      margin: theme.spacing(2),
      backgroundColor: ({ selected }: { selected: boolean }) =>
        selected ? "#D5E5FF" : "white",
      boxShadow: ({ selected }: { selected: boolean }) =>
        selected
          ? "0 0 10px rgba(0, 0, 255, 0.5)"
          : "0 2px 5px rgba(0, 0, 0, 0.1)",
      cursor: "pointer",
    },
    videoCardDetails: {
      marginBottom: theme.spacing(1),
      ...typography.circularXXBook,
    },
    descriptionContainer: {
      height: 60,
    },
    media: {
      // height: 200,
    },
  }),
)

const VideoCard = memo(
  ({ video, selected, onClick }: VideoCardProps) => {
    const classes = useStyles({ selected })

    const { name, trainer, recordingURL, description, posterImageURL } = video

    return (
      <Card className={classes.videoCardContainer} onClick={onClick}>
        <CardMedia
          height={250}
          className={classes.media}
          component="video"
          title={name}
          src={recordingURL}
          poster={posterImageURL}
          controls
        />

        <CardContent>
          <Typography className={classes.videoCardDetails}>{name}</Typography>

          <Typography className={classes.videoCardDetails}>
            Expert: {trainer.fullName}
          </Typography>

          <div className={classes.descriptionContainer}>
            <ShortText value={description} textLimit={80} />
          </div>
        </CardContent>
      </Card>
    )
  },
  (prevProps, nextProps) => {
    const areEqual =
      prevProps.selected === nextProps.selected &&
      prevProps.video.id === nextProps.video.id

    return areEqual
  },
)

export { VideoCard }
