import { Instance, SnapshotOut, types, isAlive, clone } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../extensions"
import { uniqBy, prop } from "ramda"

import { replaceObjectById } from "../../utils/replace-object-in-array"
import { removeObjectById } from "../../utils/remove-object-from-array"
import { TriCategory, TriCategoryModel } from "../tri-category"

export const LIMIT = 25
export const SORT_TITLE = "title"
export const SORT_CREATED_AT = "createdAt"

export type CATEGORIES_SORT_TYPE = typeof SORT_TITLE | typeof SORT_CREATED_AT

/**
 * Represents a pagination model.
 */
export const TriCategoriesPaginationModel = types
  .model("TriCategoriesPagination")
  .extend(withEnvironment)
  .extend(withRootStore)
  .props({
    loading: types.optional(types.boolean, false),
    next: types.optional(types.maybeNull(types.number), null),
    query: types.optional(types.maybeNull(types.string), ""),
    previous: types.optional(types.maybeNull(types.number), null),
    limit: types.optional(types.maybeNull(types.number), LIMIT),
    sortBy: types.optional(
      types.union(types.literal(SORT_CREATED_AT), types.literal(SORT_TITLE)),
      SORT_CREATED_AT,
    ),
    totalCount: types.optional(types.number, 0),
    categories: types.optional(types.array(TriCategoryModel), []),
    newTriCategory: types.optional(TriCategoryModel, () =>
      TriCategoryModel.create({
        id: "-1",
      }),
    ),
  })
  .actions((self) => ({
    setLoading(value: boolean) {
      self.loading = value
    },
    setNext(value: number | null) {
      self.next = value
    },
    setQuery(value: string) {
      self.query = value
    },
    setPrevious(value: number | null) {
      self.previous = value
    },
    setLimit(value: number | null) {
      self.limit = value
    },
    setSortBy(value: CATEGORIES_SORT_TYPE) {
      self.sortBy = value
    },
    setTotalCount(value: number) {
      self.totalCount = value
    },

    setNewTriCategory(value: TriCategory) {
      self.newTriCategory = clone(value)
    },

    setPagination(value: {
      next: number
      previous: number
      limit: number
      sortBy: CATEGORIES_SORT_TYPE
      totalCount?: number
    }) {
      self.next = value.next
      self.previous = value.previous
      self.limit = value.limit
      self.sortBy = value.sortBy
      self.totalCount = value.totalCount
    },

    setTriCategories(value: TriCategory[]) {
      self.categories.replace(
        uniqBy(prop("id"), [...self.categories, ...value]),
      )
    },

    updateTriCategory(value: TriCategory) {
      const updatedTriCategoriesList = replaceObjectById(self.categories, value)
      self.categories.replace(updatedTriCategoriesList as TriCategory[])
    },

    deleteTriCategory(value: string) {
      const updatedTriCategoriesList = removeObjectById(self.categories, value)
      self.categories.replace(updatedTriCategoriesList as TriCategory[])
      self.totalCount = self.totalCount - 1
    },

    resetNewTriCategory() {
      self.newTriCategory = TriCategoryModel.create({
        id: "-1",
      })
    },

    resetTriCategories() {
      if (isAlive(self.categories)) {
        self.categories.clear()
      }
    },
  }))
  .actions((self) => ({
    async apiAdminGetTriCategories() {
      const { api } = self.environment
      const { next, limit, sortBy, setLoading } = self

      setLoading(true)

      const { kind, data } = await api.adminGetTriCategories({
        next,
        limit,
        sortBy,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const triCategoriesPagination = {
        next: data.next,
        previous: data.previous,
        limit: data.limit,
        sortBy: data.sortBy,
        filterBy: data.filterBy,
        totalCount: data.totalCount,
      }

      self.setTriCategories(data.categories)
      self.setPagination(triCategoriesPagination)
      setLoading(false)
    },

    async apiAdminGetSingleTriCategory(categoryID: string) {
      const { api } = self.environment
      const { kind, data } = await api.adminGetSingleTriCategory({ categoryID })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.updateTriCategory(data.data as TriCategory)
    },
    async apiAdminSearchTriCategories({
      query,
    }: {
      query: string
    }): Promise<void> {
      const { api } = self.environment

      self.setLoading(true)

      const { data, kind } = await api.adminSearchTriCategories({
        query,
        limit: 20,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.setTriCategories(data.data)
      self.setLoading(false)
    },
  }))
  .actions((self) => ({
    async apiAdminCreateTriCategory(): Promise<void> {
      const { api } = self.environment

      const {
        id,
        createdAt,
        updatedAt,
        ...newCategoryParams
      } = self.newTriCategory
      const { data, kind } = await api.adminCreateTriCategory(newCategoryParams)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.setNext(0)
      self.apiAdminGetTriCategories()
      self.resetNewTriCategory()
    },

    async apiAdminDeleteTriCategory(categoryID: string): Promise<void> {
      const { api } = self.environment

      const { data, kind } = await api.adminDeleteTriCategory({
        categoryID,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      self.deleteTriCategory(categoryID)
    },

    async apiAdminEditTriCategory(): Promise<void> {
      const { api } = self.environment

      const { createdAt, updatedAt, ...updateParams } = self.newTriCategory
      const { data, kind } = await api.adminEditTriCategory(updateParams)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.updateTriCategory(data)
      self.resetNewTriCategory()
    },

    async apiAdminAddTagToTriCategory(categoryID: string, tagID: string) {
      const { api } = self.environment

      const { kind, data } = await api.adminAddTagToTriCategory({
        categoryID,
        tagID,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetSingleTriCategory(categoryID)
    },

    async apiAdminDeleteTriCategoryTag(
      categoryID: string,
      tagID: string,
    ): Promise<void> {
      const { api } = self.environment

      const { data, kind } = await api.adminDeleteTriCategoryTag({
        categoryID,
        tagID,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetSingleTriCategory(categoryID)
    },

    async apiAdminAddIdeaToTriCategory(categoryID: string, ideaID: string) {
      const { api } = self.environment

      const { kind, data } = await api.adminAddIdeaToTriCategory({
        ideaID,
        categoryID,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetSingleTriCategory(categoryID)
    },

    async apiAdminDeleteTriCategoryIdea(
      categoryID: string,
      ideaID: string,
    ): Promise<void> {
      const { api } = self.environment

      const { data, kind } = await api.adminDeleteTriCategoryIdea({
        ideaID,
        categoryID,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetSingleTriCategory(categoryID)
    },

    async apiAdminAddBehaviorToTriCategory(
      categoryID: string,
      behaviorID: string,
    ) {
      const { api } = self.environment

      const { kind, data } = await api.adminAddBehaviorToTriCategory({
        behaviorID,
        categoryID,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetSingleTriCategory(categoryID)
    },

    async apiAdminDeleteTriCategoryBehavior(
      categoryID: string,
      behaviorID: string,
    ): Promise<void> {
      const { api } = self.environment

      const { data, kind } = await api.adminDeleteTriCategoryBehavior({
        behaviorID,
        categoryID,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetSingleTriCategory(categoryID)
    },
  }))
  .actions((self) => ({
    resetPagination() {
      self.resetTriCategories()
      self.limit = LIMIT
      self.loading = false
      self.totalCount = 0
      self.next = 0
      self.previous = 0
      self.sortBy = SORT_CREATED_AT
      self.query = ""
    },
  }))

export interface TriCategoriesPagination
  extends Instance<typeof TriCategoriesPaginationModel> {}
export interface TriCategoriesPaginationSnapshot
  extends SnapshotOut<typeof TriCategoriesPaginationModel> {}
