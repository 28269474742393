import { types, Instance, SnapshotOut } from "mobx-state-tree"
import moment from "moment"

import * as customTypes from "../types"
import { ScoreType } from "../affinity-group"

export const MemberModel = types
  .model("Member")
  .props({
    personID: types.optional(types.maybeNull(types.string), ""),
    personFirstName: types.optional(types.maybeNull(types.string), null),
    personEmail: types.optional(types.maybeNull(types.string), null),
    appEngagementScore: types.optional(
      types.maybeNull(
        types.enumeration<ScoreType>("ScoreType", ["low", "medium", "high"]),
      ),
      null,
    ),
    groupEngagementScore: types.optional(
      types.maybeNull(
        types.enumeration<ScoreType>("ScoreType", ["low", "medium", "high"]),
      ),
      null,
    ),
    chatStatus: types.optional(types.maybeNull(types.string), null),
    createdAt: types.optional(customTypes.iso8601, null),
  })
  .views((self) => ({
    get createdAtFromNow() {
      return self.createdAt && moment(self.createdAt).fromNow()
    },
  }))

export interface Member extends Instance<typeof MemberModel> {}
export interface MemberSnapshot extends SnapshotOut<typeof MemberModel> {}
