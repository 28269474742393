import { useEffect, useState, useCallback } from "react"
import { useParams, useHistory } from "react-router-dom"
import { Modal, Theme } from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { observer } from "mobx-react-lite"

import { useStores } from "../../models/root-store"
import { useInterval } from "../use-interval"
import { useSnackbars } from "../use-snackbar"
import { AffinityChatComponent } from "../affinity-chat-component"
export const CHAT_REFRESH_RATE = 2000

interface RouteParams {
  personId: string
  affinityGroupId: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      justifyContent: "flex-end",
    },
    modalSubContainer: {
      display: "flex",
      height: "100%",
      width: "50%",
    },
  }),
)
const SliderChatComponent = observer(() => {
  const classes = useStyles()
  const history = useHistory()
  const goBack = () => history.goBack()

  const [isRoomUpdating, toggleRoomUpdating] = useState<boolean>(true)
  const params = useParams<RouteParams>()
  const { pilotTrainerStore, loginStore } = useStores()
  const { setAndShowSnackbar } = useSnackbars()

  const { person: currentCoach } = loginStore
  const { affinityChatSessionsPagination } = pilotTrainerStore
  const {
    currentSession,
    currentSessionMessages,
    isCurrentSessionClosed,
    apiAffinityGetParticipantSessions,
    apiGetAffinityCurrentSessionMessages,
    apiSendMessageToSession,
    resetCurrentSession,
    resetCurrentSessionMessages,
  } = affinityChatSessionsPagination

  const sendMessage = useCallback(
    async (messageData: { message: string; type?: string; meta?: string }) => {
      if (!params?.affinityGroupId || !params?.personId) return
      try {
        await apiSendMessageToSession({
          ...messageData,
          coachID: currentCoach.id,
          groupID: params.affinityGroupId,
          personID: params.personId,
          sessionID: currentSession?.isValid ? currentSession.id : undefined,
        })
      } catch (error) {
        if (error.message) {
          setAndShowSnackbar({
            text: error.message || "Failed to send message!",
            severity: "error",
          })
        }
      }
    },
    [
      params?.affinityGroupId,
      currentSession?.id,
      currentSession?.isValid,
      currentCoach.id,
      params.personId,
      apiSendMessageToSession,
      setAndShowSnackbar,
    ],
  )

  const refreshConversationRoom = useCallback(async () => {
    //let's make sure there's a roomID when refreshing.
    if (
      !params?.affinityGroupId ||
      !currentSession?.isValid ||
      isCurrentSessionClosed
    )
      return
    try {
      await apiGetAffinityCurrentSessionMessages({
        groupID: params.affinityGroupId,
        sessionID: currentSession.id,
      })
      toggleRoomUpdating(false)
    } catch (error) {
      toggleRoomUpdating(false)
      if (error.message) {
        setAndShowSnackbar({ text: error.message, severity: "error" })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    params.affinityGroupId,
    currentSession.id,
    currentSession?.isValid,
    isCurrentSessionClosed,
  ])

  useInterval(refreshConversationRoom, CHAT_REFRESH_RATE)

  //refresh participant sessions on modal open
  useEffect(() => {
    if (
      !params?.affinityGroupId ||
      !params.personId ||
      currentSession.participantID
    )
      return

    resetCurrentSession()
    resetCurrentSessionMessages()

    const refresh = async () => {
      toggleRoomUpdating(true)
      try {
        await apiAffinityGetParticipantSessions({
          groupID: params.affinityGroupId,
          participantID: params.personId,
        })
        toggleRoomUpdating(false)
      } catch (error) {
        toggleRoomUpdating(false)
        if (error.message) {
          setAndShowSnackbar({ text: error.message, severity: "error" })
        }
      }
    }

    refresh()

    return () => {
      resetCurrentSession()
      resetCurrentSessionMessages()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.affinityGroupId, params.personId])

  //refresh conversation room api when a new chat room is focused
  useEffect(() => {
    if (!params?.affinityGroupId || !currentSession?.isValid) return

    const refresh = async () => {
      resetCurrentSessionMessages()
      toggleRoomUpdating(true)
      try {
        await apiGetAffinityCurrentSessionMessages({
          groupID: params.affinityGroupId,
          sessionID: currentSession.id,
        })
        toggleRoomUpdating(false)
      } catch (error) {
        toggleRoomUpdating(false)
        if (error.message) {
          setAndShowSnackbar({ text: error.message, severity: "error" })
        }
      }
    }

    refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.affinityGroupId, currentSession.id])

  const handleClose = (e, reason) => {

    if (reason !== 'backdropClick') {
      goBack()
    }
  }

  return (
    <Modal
      open={true}
      onClose={handleClose}
      disablePortal
      className={classes.modal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
      <div className={classes.modalSubContainer}>
        <AffinityChatComponent
          messages={currentSessionMessages}
          isLoading={isRoomUpdating}
          sendMessage={sendMessage}
          hideInputComponent={isCurrentSessionClosed}
          isSlider
        />
      </div>
    </Modal>
  )
})

export { SliderChatComponent }
