import { types, Instance, SnapshotOut } from "mobx-state-tree"

export const AffinityChatAuthorModel = types
  .model("AffinityChatHistory")
  .props({
    id: types.identifier,
    avatarURL: types.maybeNull(types.string),
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    isCoach: types.maybeNull(types.boolean),
  })

export interface AffinityChatAuthor
  extends Instance<typeof AffinityChatAuthorModel> {}
export interface AffinityChatAuthorSnapshot
  extends SnapshotOut<typeof AffinityChatAuthorModel> {}
