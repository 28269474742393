import {
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
} from "@material-ui/core"

import { useSnackbars } from "../use-snackbar"
import { useStores } from "../../models/root-store"
import { ModalMode } from "./create-tri-program-modal"
import { FieldTypes } from "../admin-config-form"
import { useFormState } from "../admin-config-form/useFormState"

import { Module } from "../../models/tri-content-modules"

const useTriProgramForm = ({
  isModalOpen,
  mode,
  closeModal,
}: {
  isModalOpen: boolean
  mode: ModalMode
  closeModal: () => void
}) => {
  const { setAndShowSnackbar } = useSnackbars()

  const { adminMslEventStoreModel } = useStores()
  const { triPrograms, triContentModules } = adminMslEventStoreModel
  const {
    newTriProgram,
    resetNewTriProgram,
    apiAdminEditTriProgram,
    apiAdminAddTriProgramsModule,
    apiAdminDeleteModuleFromProgram,
  } = triPrograms

  const { modules, setModules, createTriProgramErrors: errors } = newTriProgram

  const { validateErrors, loading, setLoading, submitted } = useFormState({
    isModalOpen,
    errors,
  })

  const onCancel = () => {
    resetNewTriProgram()
    closeModal()
  }

  const onSubmit = async () => {
    const isValid = validateErrors()
    if (!isValid) {
      return
    }

    try {
      setLoading(true)

      if (mode === ModalMode.CREATE) {
        return
      } else {
        await apiAdminEditTriProgram()
        setAndShowSnackbar({
          text: "Successfully edited program !",
          severity: "success",
        })
      }

      closeModal()
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    } finally {
      setLoading(false)
    }
  }

  const handleModulesChange = async (
    event: React.SyntheticEvent<Element, Event>,
    value: Module[],
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<Module>,
  ) => {
    event.preventDefault()
    const areValidTags = value.every((e) => e?.id)
    if (areValidTags) {
      setModules(value.slice())
    }

    const { option } = details
    try {
      if (reason === "selectOption") {
        await apiAdminAddTriProgramsModule(option.id)
        setAndShowSnackbar({
          text: `Successfully added ${option.title} module !`,
        })
      }
      if (reason === "removeOption") {
        await apiAdminDeleteModuleFromProgram({
          moduleID: option.id,
          programID: newTriProgram.id,
        })
        setAndShowSnackbar({
          text: `Successfully deleted ${option.title} module !`,
        })
      }
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    }
  }

  const triProgramFormConfig = [
    {
      fieldName: "id",
      label: "ID",
      showOnlyOnEditMode: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        disabled: true,
      },
    },
    {
      fieldName: "name",
      onChangeMethod: "setName",
      label: "Name",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter name",
      },
    },
    {
      fieldName: "summary",
      onChangeMethod: "setSummary",
      label: "Surname",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter surname",
      },
    },
    {
      fieldName: "label",
      onChangeMethod: "setLabel",
      label: "Label",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter label",
      },
    },
    {
      fieldName: "active",
      onChangeMethod: "setIsActive",
      label: "Active",
      fieldType: FieldTypes.Switch,
    },
    {
      fieldName: "modules",
      onChangeMethod: "setModules",
      label: "Modules",
      fieldType: FieldTypes.Select,
      componentProps: {
        multiple: true,
        forbidDuplicates: true,
        disableClearable: true,
        options: triContentModules.modules.slice(),
        value: modules.slice(),
        onChange: handleModulesChange,

        getOptionLabel: (option: Module) => {
          return option.title
        },
        renderOption: (props, option: Module) => (
          <li {...props} key={option.id}>
            {option.title}
          </li>
        ),
        Input: {
          placeholder: "Select Modules",
        },
        showTags: true,
        tagDisplayNameField: "title",
      },
      showOnlyOnEditMode: true,
    },
  ]

  return {
    newTriProgram,
    loading,
    submitted,
    onCancel,
    onSubmit,
    errors,
    triProgramFormConfig,
  }
}

export { useTriProgramForm }
