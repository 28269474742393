import { GridColDef } from "@mui/x-data-grid"

export const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    filterable: false,
  },
  {
    field: "name",
    headerName: "Name",
    sortable: true,
    filterable: false,
  },
  {
    field: "summary",
    headerName: "Summary",
    sortable: false,
    filterable: false,
  },
  {
    field: "label",
    headerName: "Label",
    sortable: false,
    filterable: false,
  },
  {
    field: "active",
    headerName: "Active",
    sortable: false,
    filterable: false,
  },
  {
    field: "createdAt",
    headerName: "Created At",
    filterable: false,
  },
  {
    field: "updatedAt",
    headerName: "Updated At",
    sortable: true,
    filterable: false,
  },
  {
    field: "actions",
    headerName: "",
    sortable: false,
    filterable: false,
  },
]
