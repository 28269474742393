import { types, Instance, SnapshotOut } from "mobx-state-tree"

import { AffinityChatAuthorModel } from "./affinity-chat-author"
import * as customTypes from "../types"

export const AffinityChatModel = types.model("AffinityChatHistory").props({
  id: types.identifier,
  author: types.maybeNull(AffinityChatAuthorModel),
  type: types.maybeNull(types.string),
  sessionID: types.maybeNull(types.string),
  createdAt: types.optional(customTypes.iso8601, null),
})

export interface AffinityChat extends Instance<typeof AffinityChatModel> {}
export interface AffinityChatSnapshot
  extends SnapshotOut<typeof AffinityChatModel> {}
