import { Instance, SnapshotOut, types, isAlive } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../extensions"
import { Member, MemberModel } from "./member"

export const LIMIT = 30
export const LOW_APP_ENGAGEMENT = "low_app_engagement"
export const MEDIUM_APP_ENGAGEMENT = "medium_app_engagement"
export const LOW_GROUP_ENGAGEMENT = "low_group_engagement"
export const MEDIUM_GROUP_ENGAGEMENT = "medium_group_engagement"

export type FILTER_BY_TYPE =
  | typeof LOW_APP_ENGAGEMENT
  | typeof MEDIUM_APP_ENGAGEMENT
  | typeof LOW_GROUP_ENGAGEMENT
  | typeof MEDIUM_GROUP_ENGAGEMENT

/**
 * Represents a pagination model.
 */
export const AffinityGroupMembersPaginationModel = types
  .model("AffinityGroupMembersPagination")
  .extend(withEnvironment)
  .extend(withRootStore)
  .props({
    next: types.optional(types.maybeNull(types.number), null),
    limit: types.optional(types.maybeNull(types.number), LIMIT),
    filterBy: types.optional(
      types.union(
        types.literal(LOW_APP_ENGAGEMENT),
        types.literal(MEDIUM_APP_ENGAGEMENT),
        types.literal(LOW_GROUP_ENGAGEMENT),
        types.literal(MEDIUM_GROUP_ENGAGEMENT),
      ),
      MEDIUM_APP_ENGAGEMENT,
    ),
    isEndReached: types.optional(types.boolean, false),
    members: types.optional(types.array(MemberModel), []),
  })
  .actions((self) => ({
    setNext(value: number | null) {
      self.next = value
    },
    setLimit(value: number | null) {
      self.limit = value
    },
    setFilterBy(value: FILTER_BY_TYPE) {
      self.filterBy = value
    },
    setNextPage(value: { next: number | null; isEndReached: boolean }) {
      self.next = value.next
      self.isEndReached = value.isEndReached
    },

    setMembers(value: Member[]) {
      self.members.replace([...self.members, ...value])
    },

    resetMembers() {
      if (isAlive(self.members)) {
        self.members.clear()
      }
    },
  }))
  .actions((self) => ({
    async apiGetAffinityGroupMembers(groupID: string) {
      const { api } = self.environment
      const { next, limit, filterBy, setNextPage, setMembers } = self

      if (self.isEndReached) {
        return
      }

      const { kind, data } = await api.getAffinityGroupMembers({
        groupID,
        limit,
        filter_by: filterBy,
        timestamp: next || undefined,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const isEndReached = data.data.length < limit

      setMembers(data.data)
      setNextPage({
        next: isEndReached ? null : data.paginationMetadata.next,
        isEndReached,
      })
    },
    async apiSearchAffinityGroupMembers(groupID: string, query: string) {
      const { api } = self.environment
      const { limit, next, setNextPage, setMembers } = self

      if (self.isEndReached) {
        return
      }

      const { kind, data } = await api.searchAffinityGroupMembers({
        groupID,
        limit,
        query,
        timestamp: next || undefined,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const isEndReached = data.data.length < limit

      setMembers(data.data)
      setNextPage({
        next: isEndReached ? null : data.paginationMetadata.next,
        isEndReached,
      })
    },
  }))
  .actions((self) => ({
    resetPagination() {
      self.limit = LIMIT
      self.filterBy = MEDIUM_APP_ENGAGEMENT
      self.next = null
      self.isEndReached = false
      self.resetMembers()
    },
  }))

export interface AffinityGroupMembersPagination
  extends Instance<typeof AffinityGroupMembersPaginationModel> {}
export interface AffinityGroupMembersPaginationSnapshot
  extends SnapshotOut<typeof AffinityGroupMembersPaginationModel> {}
