import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { Grid, DialogContent, CircularProgress } from "@material-ui/core"
import InfiniteScroll from "react-infinite-scroll-component"

import { useStores } from "../../models/root-store"
import { useChatContent } from "./contentContext"
import { EmptyList } from "./empty-list"
import { VideoCard } from "./video-card"

const ContentList = observer(() => {
  const [loading, setLoading] = useState(false)
  const {
    searchTerm,
    selectedVideoID,
    setSelectedVideoID,
    searchCompleted,
    isSearchActive,
  } = useChatContent()
  const { pilotTrainerStore } = useStores()

  const {
    trainEpisodesPagination,
    apiPilotCoachSearchTrainContent,
  } = pilotTrainerStore
  const { episodes, isEndReached, next } = trainEpisodesPagination

  const handleCardSelect = (id: string) => {
    if (id === selectedVideoID) {
      setSelectedVideoID(null)
    } else {
      setSelectedVideoID(id)
    }
  }

  const handlePagination = async () => {
    if (!loading) {
      setLoading(true)
      await apiPilotCoachSearchTrainContent({ query: searchTerm, next })
      setLoading(false)
    }
  }

  return (
    <DialogContent dividers id="scrollableDialog">
      <InfiniteScroll
        dataLength={episodes.length}
        next={handlePagination}
        hasMore={isEndReached}
        loader={<CircularProgress size={20} />}
        scrollableTarget="scrollableDialog">
        <div className="container">
          <div className="row">
            <Grid container spacing={2} justifyContent="center" mt={2}>
              {episodes.length === 0 && (
                <EmptyList
                  searchCompleted={searchCompleted && isSearchActive}
                />
              )}

              {episodes.map((video) => (
                <Grid item xs={12} sm={6} md={4} key={video.id}>
                  <VideoCard
                    video={video}
                    selected={selectedVideoID === video.id}
                    onClick={() => handleCardSelect(video.id)}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </InfiniteScroll>
    </DialogContent>
  )
})

export { ContentList }
