import { Theme, CircularProgress } from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { observer } from "mobx-react-lite"
import React, { useEffect, useState, useRef } from "react"
import clsx from "clsx"
import moment from "moment"
import InfiniteScroll from "react-infinite-scroll-component"
import { ChatMessage } from "../../models/chat-message"
import { DirectChatMessagesMeta } from "../../models/direct-chat-messages/direct-chat-messages-meta"
import { useStores } from "../../models/root-store"
import { ChatBubble } from "../chat-bubble/chat-bubble"
import { usePrevious } from "../use-previous"
import { typography } from "../../services/theme/typography"
import { DeleteMessageModal } from "./delete-message-modal"
import { getGroupedMessagesByDate } from "./utils"

interface ChatBubbleGroupProps {
  messages: Array<ChatMessage>
  roomTitle?: string
  showAuthorName?: boolean
  chatMeta?: DirectChatMessagesMeta | null
  isEndReached?: boolean
  isPaginating?: boolean
  isLoading?: boolean
  EmptyComponent?: React.ReactNode
  wrapperStyle?: string
  handleScrollPagination: () => void
  handleDeleteMessage: (messageId: string) => void
  handleRenderRow?: (message: ChatMessage) => React.ReactNode
}

const ROOM_TITLE_HEIGHT = 68
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      position: "relative",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      background: theme.palette.common.white,
    },
    wrapper: {
      overflow: "auto",
      display: "flex",
      flexDirection: "column-reverse",
      padding: theme.spacing(3, 3, 0, 6),
      flex: 1,
    },
    wrapperWithTitle: {
      paddingTop: ROOM_TITLE_HEIGHT,
    },
    roomTitle: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: ROOM_TITLE_HEIGHT,
      position: "absolute",
      borderBottom: "0.5px solid #E0E0E0",
      paddingLeft: theme.spacing(3.5),
      fontSize: theme.spacing(3),
      color: "#212121",
      background: theme.palette.common.white,
      borderTopRightRadius: 14,
      borderTopLeftRadius: 14,
      ...typography.circularXXMedium,
    },
    progressLoader: {
      marginBottom: theme.spacing(2),
      alignSelf: "center",
    },
    dateText: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: theme.spacing(4),
      fontSize: theme.spacing(2),
      color: "#485359",
      ...typography.circularXXMedium,
    },
    divider: {
      width: 170,
      height: 1,
      margin: theme.spacing(0, 3),
      background: "#B0BABF",
    },
  }),
)

export const ChatBubbleGroup = observer(
  ({
    messages,
    roomTitle,
    showAuthorName,
    isEndReached,
    isPaginating,
    isLoading,
    EmptyComponent,
    wrapperStyle,
    handleScrollPagination,
    handleDeleteMessage,
    handleRenderRow,
  }: ChatBubbleGroupProps) => {
    const classes = useStyles()
    const messagesEndRef = useRef(null)
    const { loginStore } = useStores()
    const { person } = loginStore

    const [disableScrollToBottom, setDisableScrollToBottom] = useState<boolean>(
      false,
    )
    const [
      selectedMessageToDelete,
      setSelectedMessageToDelete,
    ] = useState<ChatMessage>()
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)

    const openDeleteModal = (message: ChatMessage) => {
      setSelectedMessageToDelete(message)
      setIsDeleteModalOpen(true)
    }

    const closeDeleteModal = () => {
      setIsDeleteModalOpen(false)
    }

    const handleDeleteModalClose = (event, reason) => {
      if (reason === "backdropClick") {
        closeDeleteModal()
      }
    }

    const scrollToBottom = () => {
      messagesEndRef?.current?.scrollIntoView?.()
    }

    const previousMessagesCount = usePrevious(messages.length)

    /**
     * Disable scroll to bottom handler while paginating
     */
    useEffect(() => {
      if (isPaginating && !disableScrollToBottom) {
        setDisableScrollToBottom(true)
      }
    }, [isPaginating, disableScrollToBottom])

    /**
     * scroll to bottom if messages length changes
     * e.g: if new messages appears
     */
    useEffect(() => {
      if (!disableScrollToBottom && previousMessagesCount !== messages.length) {
        scrollToBottom()
      } else if (disableScrollToBottom) {
        setDisableScrollToBottom(false)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messages, previousMessagesCount, isPaginating])

    const formattedGroupedMessagesList = getGroupedMessagesByDate(messages)

    const _rowRenderer = (message: ChatMessage) => {
      const isMessageFromCurrentPerson = person.id === message.author.id
      const isMessageFromCoach = message?.author?.isCoach

      return (
        <ChatBubble
          key={message.id}
          message={message}
          isMessageFromCurrentPerson={isMessageFromCurrentPerson}
          isMessageFromCoach={isMessageFromCoach}
          showAuthorName={showAuthorName}
          onMessageDelete={() => openDeleteModal(message)}
        />
      )
    }

    if (isLoading) {
      return <CircularProgress />
    }

    if (!!EmptyComponent && messages.length === 0) {
      return <>{EmptyComponent}</>
    }

    const messagesGroupedByDate = formattedGroupedMessagesList.reduce<
      Record<string, typeof formattedGroupedMessagesList>
    >((acc, item) => {
      const dateKey = moment(item.createdAt).format("YYYY-MM-DD")

      if (!acc[dateKey]) {
        acc[dateKey] = []
      }
      acc[dateKey].push(item)
      return acc
    }, {})

    const formatTime = (date) => {
      const today = moment()
      const yesterday = moment().subtract(1, "day")

      if (moment(date).isSame(today, "day")) {
        return "Today"
      } else if (moment(date).isSame(yesterday, "day")) {
        return "Yesterday"
      } else {
        return moment(date).format("MMMM Do YYYY")
      }
    }

    return (
      <div className={classes.content}>
        <div
          id="scrollableDiv"
          className={clsx(classes.wrapper, {
            [classes.wrapperWithTitle]: Boolean(roomTitle),
            [wrapperStyle]: Boolean(wrapperStyle),
          })}>
          <div ref={messagesEndRef} />

          <InfiniteScroll
            dataLength={formattedGroupedMessagesList.length}
            next={handleScrollPagination}
            style={{ display: "flex", flexDirection: "column-reverse" }}
            inverse={true}
            hasMore={!isEndReached}
            loader={<CircularProgress className={classes.progressLoader} />}
            scrollableTarget="scrollableDiv">
            {Object.entries(messagesGroupedByDate).map(([date, messages]) => {
              const reversedMessages = messages.reverse()

              return (
                <div>
                  <div className={classes.dateText}>
                    <div className={classes.divider} />
                    {formatTime(date)}
                    <div className={classes.divider} />
                  </div>

                  {reversedMessages.map(handleRenderRow || _rowRenderer)}
                </div>
              )
            })}
          </InfiniteScroll>
        </div>

        {Boolean(roomTitle) && (
          <div className={classes.roomTitle}>{roomTitle}</div>
        )}

        <DeleteMessageModal
          open={isDeleteModalOpen}
          onClose={handleDeleteModalClose}
          closeModal={closeDeleteModal}
          handleDeleteMessage={handleDeleteMessage}
          message={selectedMessageToDelete}
        />
      </div>
    )
  },
)
