import { Instance, SnapshotOut, types, isAlive, clone } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../extensions"
import { replaceObjectById } from "../../utils/replace-object-in-array"

import { TriProgram, TriProgramModel } from "../tri-program/tri-program"
import { ModuleModel } from "../tri-content-modules"

export const LIMIT = 25

/**
 * Represents a pagination model.
 */
export const TriProgramsModel = types
  .model("TriPrograms")
  .extend(withEnvironment)
  .extend(withRootStore)
  .props({
    programs: types.optional(types.array(TriProgramModel), []),
    newTriProgram: types.optional(TriProgramModel, () =>
      TriProgramModel.create({
        id: "-1",
      }),
    ),
  })
  .actions((self) => ({
    setTriPrograms(value: TriProgram[]) {
      self.programs.replace(value)
    },
    setNewTriProgram(value: TriProgram) {
      self.newTriProgram = clone(value)
    },

    resetNewTriProgram() {
      self.newTriProgram = TriProgramModel.create({
        id: "-1",
      })
    },

    resetTriPrograms() {
      if (isAlive(self.programs)) {
        self.programs.clear()
      }
    },

    updateTriProgram(value: TriProgram) {
      const updatedTriProgramsList = replaceObjectById(self.programs, value)
      self.programs.replace(updatedTriProgramsList as TriProgram[])
    },
  }))
  .actions((self) => ({
    async apiAdminGetTriPrograms() {
      const { api } = self.environment
      const { kind, data } = await api.adminGetTriPrograms()

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      self.setTriPrograms(data)
    },

    async apiAdminGetTriProgramModules(programID: string) {
      const { api } = self.environment
      const { kind, data } = await api.adminGetTriProgramModules({ programID })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      const modules = data.modules.map((module) => ModuleModel.create(module))
      self.newTriProgram.setModules(modules)
    },
  }))
  .actions((self) => ({
    async apiAdminEditTriProgram(): Promise<void> {
      const { api } = self.environment

      const { createdAt, updatedAt, ...updateParams } = self.newTriProgram

      const { data, kind } = await api.adminEditTriProgram(updateParams)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.updateTriProgram(data)
    },

    async apiAdminEditTriProgramActiveStatus(
      program: TriProgram,
    ): Promise<void> {
      const { api } = self.environment

      const { data, kind } = await api.adminEditTriProgram(program)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.updateTriProgram(data)
    },

    async apiAdminAddTriProgramsModule(moduleID: string): Promise<void> {
      const { api } = self.environment

      const { id } = self.newTriProgram

      const { data, kind } = await api.adminAddTriProgramsModule({
        programID: id,
        moduleID,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetTriPrograms()
    },

    async apiAdminDeleteModuleFromProgram({
      programID,
      moduleID,
    }: {
      moduleID: string
      programID: string
    }): Promise<void> {
      const { api } = self.environment

      const { data, kind } = await api.adminDeleteModuleFromProgram({
        programID,
        moduleID,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetTriPrograms()
    },
  }))

export interface TriPrograms extends Instance<typeof TriProgramsModel> {}
export interface TriProgramsSnapshot
  extends SnapshotOut<typeof TriProgramsModel> {}
