import { Stack, Button, Theme } from "@material-ui/core"
import { GridCellProps } from "@mui/x-data-grid"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import moment from "moment"
import { CustomTooltip, ShortText } from "../admin-form-components"
import { TriCategoryActions } from "./tri-category-actions"
import { useStores } from "../../models/root-store"
import { TriCategory } from "../../models/tri-category"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionButton: {
      marginTop: "10px !important",
    },
    image: { width: "100%" },
  }),
)

interface CellComponentProps extends GridCellProps {
  openEditTriCategoryModal?: (event: TriCategory) => void
  triCategoryID?: string
}
const CellComponent = ({
  openEditTriCategoryModal,
  triCategoryID,
  ...props
}: CellComponentProps) => {
  const classes = useStyles()
  const { adminMslEventStoreModel } = useStores()
  const { triCategoriesPagination } = adminMslEventStoreModel

  const triCategory = triCategoriesPagination.categories.find(
    (category) => category.id === triCategoryID,
  )

  switch (props.field) {
    case "createdAt":
      return (
        <>
          {props.value
            ? moment(props.value).format("MMM DD, YYYY hh:mm a")
            : "N/A"}
        </>
      )

    case "updatedAt":
      return (
        <>
          {props.value
            ? moment(props.value).format("MMM DD, YYYY hh:mm a")
            : "N/A"}
        </>
      )

    case "iconURI":
      return (
        <>
          {!!props.value ? (
            <CustomTooltip title={props.value}>
              <img src={props.value} alt="icon URI" className={classes.image} />
            </CustomTooltip>
          ) : (
            "N/A"
          )}
        </>
      )

    case "actions":
      return (
        <Stack flex={1} alignItems="center" flexWrap="wrap" spacing={1}>
          <Button
            variant="contained"
            className={classes.actionButton}
            onClick={() => openEditTriCategoryModal(triCategory)}>
            Edit
          </Button>

          <TriCategoryActions triCategoryID={triCategoryID} />
        </Stack>
      )

    default:
      return <ShortText value={props.value} />
  }
}

export { CellComponent }
