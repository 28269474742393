import { Stack, Typography, Theme } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"

import { typography } from "../../services/theme/typography"
import { ReactComponent as EmptyInbox } from "../../assets/images/empty-inbox.svg"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoTitle: {
      fontSize: theme.spacing(2),
      color: theme.palette.common.black,
      textAlign: "center",
      ...typography.circularXXMedium,
    },
    infoText: {
      fontSize: theme.spacing(2),
      color: "#677278",
      textAlign: "center",
      ...typography.circularXXMedium,
    },
  }),
)

const EmptyRoom = ({
  title,
  subtitle,
}: {
  title: string | React.ReactNode
  subtitle: string | React.ReactNode
}) => {
  const classes = useStyles()

  return (
    <Stack flex={1} justifyContent="center" alignItems="center" spacing={2}>
      <EmptyInbox />

      <Stack spacing={1}>
        <Typography className={classes.infoTitle}>{title}</Typography>

        <Typography className={classes.infoText}>{subtitle}</Typography>
      </Stack>
    </Stack>
  )
}

export { EmptyRoom }
