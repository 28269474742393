import { GridColDef } from "@mui/x-data-grid"

export const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    sortable: false,
    filterable: false,
  },
  {
    field: "title",
    headerName: "Title",
    sortingOrder: ["asc", null],
    filterable: false,
  },
  {
    field: "summary",
    headerName: "Summary",
    sortable: false,
    filterable: false,
  },
  {
    field: "iconURI",
    headerName: "Icon",
    sortable: false,
    filterable: false,
  },
  {
    field: "createdAt",
    headerName: "Created At",
    filterable: false,
  },
  {
    field: "updatedAt",
    headerName: "Updated At",
    filterable: false,
    sortable: false,
  },
  {
    field: "actions",
    headerName: "",
    sortable: false,
    filterable: false,
  },
]
