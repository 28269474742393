import { useEffect, useState, useCallback, useRef } from "react"
import { observer } from "mobx-react-lite"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import AddIcon from "@mui/icons-material/Add"

import { Theme, Stack, Typography, Button } from "@material-ui/core"
import { GridCellProps } from "@mui/x-data-grid"
import { useStores } from "../../models/root-store"
import { typography } from "../../services/theme/typography"

import { CustomGridTable, TableCell } from "../custom-grid-table"
import { CellComponent } from "./cell-component"
import { LIMIT } from "../../models/tri-ideas-pagination"
import { CreateTriBehaviorModal, ModalMode } from "./create-tri-behavior-modal"
import { useTable } from "./useTable"
import { columns } from "./table-config"
import { TriBehavior } from "../../models/tri-behavior"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: "1 1 1px",
      flexDirection: "column",
      overflow: "auto",
      padding: theme.spacing(4, 12, 7, 6),
    },
    tableLabel: {
      fontSize: theme.spacing(2.5),
      lineHeight: theme.spacing(3),
      color: theme.palette.text.secondary,
      ...typography.circularXXMedium,
    },
    label: {
      fontWeight: "bold",
      fontSize: theme.spacing(2.25),
    },

    tableCell: {
      width: "20% !important",
      maxWidth: "20% !important",
      display: "flex",
      borderBottom: "unset",
      wordBreak: "break-word",
    },
    tableColumnHeader: {
      width: "20% !important",
      minWidth: "unset !important",
      maxWidth: "unset !important",
      height: "unset !important",
    },
  }),
)

const AdminTriBehaviorsList = observer(() => {
  const classes = useStyles()
  const [firstLoading, toggleFirstLoading] = useState(true)
  const [
    isCreateTriBehaviorModalOpen,
    toggleCreateTriBehaviorModalOpen,
  ] = useState(false)
  const [triBehaviorModalMode, setTriBehaviorModalMode] = useState<ModalMode>(
    ModalMode.CREATE,
  )
  const tableRef = useRef()

  const { adminMslEventStoreModel } = useStores()
  const {
    triBehaviorsPagination,
    triIdeasPagination,
    apiAdminGetTags,
  } = adminMslEventStoreModel
  const { setNewTriBehavior, resetNewTriBehavior } = triBehaviorsPagination

  const {
    behaviors,
    previous,
    sortBy,
    totalCount,
    next,
    loading,
    setPagination,
    setLoading,
    setSortBy,
    resetPagination,
  } = triBehaviorsPagination

  useEffect(() => {
    const initialFetch = async () => {
      try {
        await apiAdminGetTags()
      } catch (e) {}
      toggleFirstLoading(false)
      setLoading(false)
    }

    resetPagination()
    fetchProducts()
    initialFetch()

    return () => {
      triBehaviorsPagination.resetPagination()

      triIdeasPagination.resetPagination()
      triIdeasPagination.resetPaginationFilters()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchProducts = async () => {
    try {
      await triBehaviorsPagination.apiAdminGetTriBehaviors()
      toggleFirstLoading(false)
      setLoading(false)
    } catch (e) {
      toggleFirstLoading(false)
      setLoading(false)
    }
  }

  const openCreateTriBehaviorModal = () => {
    resetNewTriBehavior()
    setTriBehaviorModalMode(ModalMode.CREATE)
    toggleCreateTriBehaviorModalOpen(true)
  }

  const openEditTriBehaviorModal = useCallback((event: TriBehavior) => {
    setNewTriBehavior(event)
    setTriBehaviorModalMode(ModalMode.EDIT)
    toggleCreateTriBehaviorModalOpen(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeCreateTriBehaviorModal = () => {
    toggleCreateTriBehaviorModalOpen(false)
  }

  const handlePaginationChange = async ({ page, pageSize }) => {
    setPagination({
      limit: pageSize,
      next: next,
      previous: previous,
      sortBy,
      totalCount,
    })
    await triBehaviorsPagination.apiAdminGetTriBehaviors()
  }

  const MemoTableCell = useCallback(
    (props: GridCellProps) => (
      <TableCell {...props} tableCellClass={classes.tableCell}>
        <CellComponent
          {...props}
          openEditTriBehaviorModal={openEditTriBehaviorModal}
          triBehaviorID={props.rowId as string}
        />
      </TableCell>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const { handleSortModelChange } = useTable({
    fetchProducts,
    resetPagination,
    setLoading,
    setSortBy,
  })

  return (
    <div className={classes.root}>
      <Stack
        direction="row"
        flex={1}
        justifyContent="space-between"
        alignItems="center">
        <Typography className={classes.tableLabel}>Hows</Typography>

        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={openCreateTriBehaviorModal}>
          Add How
        </Button>
      </Stack>

      <CustomGridTable
        apiRef={tableRef}
        columns={columns}
        aria-label="tri-behaviors-table"
        cell={MemoTableCell}
        rows={loading ? [] : behaviors.slice()}
        onPaginationModelChange={handlePaginationChange}
        rowCount={totalCount}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: LIMIT,
            },
          },
        }}
        pageSizeOptions={[25, 50, 100]}
        emptyStateLabel="When you create some products, they will appear here"
        loading={loading || firstLoading}
        classes={{
          columnHeader: classes.tableColumnHeader,
        }}
        sortingMode="server"
        filterMode="server"
        onSortModelChange={handleSortModelChange}
      />

      {isCreateTriBehaviorModalOpen && (
        <CreateTriBehaviorModal
          open={isCreateTriBehaviorModalOpen}
          closeModal={closeCreateTriBehaviorModal}
          mode={triBehaviorModalMode}
        />
      )}
    </div>
  )
})

export { AdminTriBehaviorsList }
